import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import {
  AppointmentMifQuestion,
  ExtendedMifResponseItem,
  MifResponseItem
} from 'services/mifs/mifs.types';

import { selectMifInfo } from 'store';
import { setAppointmentMif } from 'store/mif/mifSlice';

import Heading from 'pages/SignUp/components/Heading';

import Slider from 'features/Slider';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import CheckboxGroup from 'shared/CheckboxGroup';
import RadioGroup from 'shared/RadioGroup';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';

import { DynamicQuestionProps } from './dynamicQuestion.types';
import InfoScreen from './InfoScreen';

const DynamicQuestion: React.FC<DynamicQuestionProps> = ({
  config,
  onClickContinue,
  mifLength,
  currentQuestionIndex,
  groupTitle,
  onDQCondition
}) => {
  const dispatch = useAppDispatch();
  const { isEdit } = useMifNavigate();
  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const isInfoScreen = config.type === 'info';

  const [textareaError, setTextAreaError] = useState('');

  const answerOnCurrentQuestion = appointmentMif.find((item) =>
    isInfoScreen ? undefined : item.question === config.question.value
  );

  const handleSubmitTextArea = () => {
    if (!('validation' in config) || !config.validation) {
      return onClickContinue();
    }
    if (
      config.validation?.minLength &&
      String(answerOnCurrentQuestion?.answer).length < config.validation.minLength
    ) {
      setTextAreaError(`Minimum ${config.validation.minLength} characters`);
    }
    if (
      config.validation?.maxLength &&
      String(answerOnCurrentQuestion?.answer).length > config.validation.maxLength
    ) {
      setTextAreaError(`Maximum ${config.validation.maxLength} characters`);
    }
    const pattern = new RegExp(config.validation.pattern);
    if (!pattern.test(answerOnCurrentQuestion?.answer as string)) {
      setTextAreaError(`Please enter a valid text`);
    }
  };

  const handleSubmitAnswer = (
    type: AppointmentMifQuestion['config']['type'] | 'textarea',
    value: string | number | boolean,
    option?: string
  ) => {
    let payload: MifResponseItem | ExtendedMifResponseItem = {
      question: '',
      answer: ''
    };
    let shouldPreventFromContinue = false;
    if (isInfoScreen) {
      return;
    }
    switch (type) {
      case 'info':
        break;
      case 'radio':
        const selectedOption = config.options.find((o) => o.value === value);
        if (selectedOption?.requireSpecialistConsultation) {
          onDQCondition();
          shouldPreventFromContinue = true;
          break;
        }
        payload = { question: config.question.value, answer: String(value) };
        break;
      case 'textarea':
        payload = {
          question: config.question.value,
          answer: answerOnCurrentQuestion?.answer as string[],
          textAreaFields: [
            ...(answerOnCurrentQuestion?.textAreaFields ?? [])?.filter((i) => i.option !== option),
            {
              option: option ?? '',
              value: String(value)
            }
          ]
        };
        break;
      case 'checkbox':
        if (value === 'none') {
          payload = {
            question: config.question.value,
            answer:
              Array.isArray(answerOnCurrentQuestion?.answer) &&
              answerOnCurrentQuestion?.answer.includes(value)
                ? []
                : [value],
            textAreaFields: undefined
          };
        } else if (typeof value === 'string') {
          if (Array.isArray(answerOnCurrentQuestion?.answer)) {
            const newAnswer = answerOnCurrentQuestion.answer.includes(value)
              ? answerOnCurrentQuestion.answer.filter((v) => v !== value)
              : [...answerOnCurrentQuestion.answer, value];
            if (newAnswer.length > 1 && newAnswer.includes('none')) {
              newAnswer.splice(newAnswer.indexOf('none'), 1);
            }
            payload = { question: config.question.value, answer: newAnswer };
            if (answerOnCurrentQuestion?.textAreaFields && newAnswer.includes('other')) {
              payload.textAreaFields = answerOnCurrentQuestion.textAreaFields;
            }
          } else {
            payload = { question: config.question.value, answer: [value] };
          }
        } else {
          throw new Error('Checkbox value should be a string');
        }
        break;
      case 'text':
        setTextAreaError('');
        payload = {
          question: config.question.value,
          answer: value,
          questionContext: config.questionContext,
          group: config.group,
          groupTitle
        };
        break;
      case 'number':
        const getAnswer = () => {
          if (value === 'can_not_complete') {
            return value;
          }
          // uncomment if we'll need a different behavior for null values
          // if (value === 'null') {
          //   return '';
          // }
          if (value === '') {
            return value;
          }
          return Number(value);
        };

        payload = {
          question: config.question.value,
          answer: getAnswer(),
          questionContext: config.questionContext,
          group: config.group,
          groupTitle
        };
        break;
      default:
        break;
    }
    dispatch(setAppointmentMif([payload]));
    const shouldPreventMoveToTheNextStep = () => {
      if (isInfoScreen) {
        return false;
      }
      if (shouldPreventFromContinue) {
        return true;
      }
      const option = config.options?.find((i) => i.value === value);
      return (
        ['checkbox', 'textarea', 'number', 'text'].includes(type) ||
        (type === 'radio' && !!option?.textArea)
      );
    };
    !shouldPreventMoveToTheNextStep() && onClickContinue();
  };

  const handleContinueCheckbox = () => {
    const shouldDQ =
      'options' in config &&
      config?.options.some(
        (i) =>
          Array.isArray(answerOnCurrentQuestion?.answer) &&
          answerOnCurrentQuestion?.answer?.includes(i.value) &&
          i.requireSpecialistConsultation
      );
    if (shouldDQ) {
      return onDQCondition();
    }
    onClickContinue();
  };

  const isShowSelectedOption = () => {
    const selectedOption =
      'options' in config &&
      config.options.find((i) => i.value === answerOnCurrentQuestion?.answer);
    return selectedOption ? !!selectedOption?.textArea : false;
  };

  const getSliderValue = () => {
    return isNaN(Number(answerOnCurrentQuestion?.answer))
      ? undefined
      : (Number(answerOnCurrentQuestion?.answer) ?? undefined);
  };

  const content = () => {
    switch (config.type) {
      case 'info':
        return <InfoScreen config={config} onClickContinue={onClickContinue} />;
      case 'radio':
        return (
          <div className="flex h-full flex-col gap-8">
            <RadioGroup
              items={config.options}
              selectedItem={isShowSelectedOption() ? String(answerOnCurrentQuestion?.answer) : ''}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('radio', v)}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            {config.options.find((i) => i.value === answerOnCurrentQuestion?.answer)?.textArea && (
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                fullWidthOnMobile
                onClick={onClickContinue}
              >
                {isEdit ? 'Save' : 'Continue'}
              </Common.Button>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <div className="flex h-full flex-col gap-8">
            <CheckboxGroup
              items={config.options}
              selectedItems={(answerOnCurrentQuestion?.answer as string[]) ?? []}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('checkbox', String(v))}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!(answerOnCurrentQuestion?.answer as string[])?.length}
              fullWidthOnMobile
              onClick={handleContinueCheckbox}
            >
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </div>
        );
      case 'number':
        if (config?.inputType === 'slider') {
          return (
            <div className="flex flex-col gap-6 max-md:h-full md:gap-16">
              <Slider
                defaultPivot={(config.validation.max + config.validation.min) / 2}
                max={config.validation.max}
                min={config.validation.min || 0}
                value={getSliderValue()}
                onChange={(v) => handleSubmitAnswer('number', String(v))}
              />
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                disabled={!answerOnCurrentQuestion?.answer && answerOnCurrentQuestion?.answer !== 0}
                fullWidthOnMobile
                onClick={onClickContinue}
              >
                {isEdit ? 'Save' : 'Continue'}
              </Common.Button>
            </div>
          );
        }
        return (
          <div className="flex flex-col gap-6 max-md:h-full">
            <Common.Input
              placeholder={config.answerPlaceholder}
              type="text"
              value={
                answerOnCurrentQuestion?.answer === 'can_not_complete'
                  ? ''
                  : !!answerOnCurrentQuestion?.answer || answerOnCurrentQuestion?.answer === 0
                    ? String(answerOnCurrentQuestion?.answer)
                    : ''
              }
              onChange={(e) => handleSubmitAnswer('number', e.target.value.replace(/\D/g, ''))}
            />
            {config.options.length &&
              config.options.map((option) =>
                option.type === 'checkbox' ? (
                  <Common.Checkbox
                    checked={answerOnCurrentQuestion?.answer === 'can_not_complete'}
                    color="blue"
                    key={option.value}
                    onChange={() => {
                      const newValue =
                        answerOnCurrentQuestion?.answer === 'can_not_complete'
                          ? ''
                          : 'can_not_complete';

                      handleSubmitAnswer('text', newValue);
                    }}
                  >
                    {option.label}
                  </Common.Checkbox>
                ) : null
              )}
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!answerOnCurrentQuestion?.answer && answerOnCurrentQuestion?.answer !== 0}
              fullWidthOnMobile
              onClick={onClickContinue}
            >
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </div>
        );

      case 'text':
        return (
          <div className="flex flex-col gap-6 max-md:h-full">
            <Common.TextArea
              errors={
                textareaError
                  ? {
                      type: 'error',
                      message: textareaError
                    }
                  : undefined
              }
              helper={textareaError}
              placeholder={config.answerPlaceholder}
              value={
                !!answerOnCurrentQuestion?.answer ? String(answerOnCurrentQuestion?.answer) : ''
              }
              onChange={(e) => handleSubmitAnswer('text', e.target.value)}
            />
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!answerOnCurrentQuestion?.answer && answerOnCurrentQuestion?.answer !== 0}
              fullWidthOnMobile
              onClick={handleSubmitTextArea}
            >
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </div>
        );
    }
  };

  return (
    <SlideAnimateWrapper className="flex h-full flex-col gap-6" key={config._id}>
      {config.type !== 'info' && (
        <Heading
          category={`Question ${currentQuestionIndex} of ${mifLength}`}
          subtitle={config.question.description}
          title={config.question.label}
        />
      )}
      {content()}
    </SlideAnimateWrapper>
  );
};

export default DynamicQuestion;
