export type SrcOfAppointmentScheduling = 'checker' | 'appMobile' | 'default';

import { PrescriptionForType } from 'widgets/createAppointment/extendedFlow/PrescriptionFor/prescriptionFor.types';
import { PrescriptionsValueType } from 'widgets/createAppointment/extendedFlow/PrescriptionsType/prescriptionsType.types';

import { AppointmentCallMethod, AppointmentTypeItem } from 'models/appointment.types';
import { PricePoint } from 'models/plans.types';

export type StepName = PossibleStepsType[number];

export const CREATE_APPOINTMENT_STEPS = [
  'choose-type-of-care',
  'red-flags',
  'prescription-for',
  'appointment-types-picker',
  'prescriptions-type',
  'is-renew',
  'mif',
  'create-account-intro',
  'create-account-personal-details',
  'create-account-address',
  'create-account-dob',
  'create-account-phone',
  'create-account-password',
  'qualified-for-async',
  'details-for-provider',
  'subscription-required',
  'date-time',
  'payment-checkout',
  'confirmation',
  'success'
] as const;

export type CareTypes = 'talk-to-a-doctor' | 'prescriptions' | 'shop-labs';

export type PossibleStepsType = typeof CREATE_APPOINTMENT_STEPS;

export interface MembershipData {
  freeAppointmentInfo: {
    bookedFreeAppointmentDate?: string;
    freeAppointmentsAmount?: number;
    isFree: boolean;
    periodEnd: string;
    periodStart: string;
  };
  newPP: PricePoint | null;
  newPlanID: string;
}

export type FillAppointmentDataProps =
  | {
      data: CareTypes;
      step: 'choose-type-of-care';
    }
  | {
      step: 'red-flags';
    }
  | {
      step: 'is-renew';
    }
  | {
      data: AppointmentTypeItem;
      step: 'appointment-types-picker';
    }
  | {
      data: {
        appointmentDescription: string;
        hasAccount: boolean;
      };
      step: 'details-for-provider';
    }
  | {
      data: {
        displayName?: string;
        doctorId?: string;
        endTime: string;
        startTime: string;
      };
      step: 'date-time';
    }
  | {
      data: {
        prescriptionForValue: PrescriptionForType;
      };
      step: 'prescription-for';
    }
  | {
      data: {
        appointmentTypeId?: string;
        displayName?: string;
        prescriptionsTypeValue: PrescriptionsValueType;
      };
      step: 'prescriptions-type';
    }
  | {
      data: {
        callType: AppointmentCallMethod;
      };
      step: 'subscription-required';
    }
  | {
      data: {
        email: string;
        firstName: string;
        lastName: string;
      };
      step: 'create-account-personal-details';
    };
