import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps, WmFlowType } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { useAppSelector, useQuery } from 'hooks';
import { useGetSelectedItem } from 'hooks/useGetSelectedItem';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { PathName } from 'utils/enums';
import { buildSearch } from 'utils/helpers';

import Heading from '../../components/Heading';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';

const Pancreatitis: React.FC<ReducedSignupStepProps & { flow?: WmFlowType }> = ({
  moveToStep,
  flow = 'onboarding'
}) => {
  const { value, setValue } = useGetSelectedItem('history_of_pancreatitis');

  const navigate = useNavigate();
  const query = useQuery();
  const { send, isLoading } = useSubmitOrchestrateForm();
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);

  const handleSelect = (v: boolean) => {
    setValue(v);
    if (v === true) {
      navigate({
        search: buildSearch(
          flow === 'authorized' ? 's=wm-pancreatitis-question&' : '',
          `hideProgress=true&subStep=fail-pancreatitis-question&answer=${v}`
        )
      });
      return;
    } else {
      send(
        'mif_qa',
        [{ answer: v, question: 'history_of_pancreatitis' }],
        () => {
          setValue(undefined);
          moveToStep('next');
        },
        undefined,
        () => {
          setValue(undefined);
        }
      );
    }
  };

  const handleProspect = () => {
    if (flow === 'authorized') {
      return navigate(PathName.Dashboard);
    }
    const answer = query.get('answer');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer: answer === 'true', question: 'history_of_pancreatitis' }],
        () => {
          moveToStep(-1);
        },
        'PROSPECT'
      );
    } else {
      moveToStep(-1);
    }
  };

  const isErrorState = query.get('subStep') === 'fail-pancreatitis-question';

  return (
    <div className="flex flex-col gap-6">
      {isErrorState ? (
        <>
          <h2 className="wm-signup-title text-center" data-testid="header">
            We regret to inform you that you are not suitable for GLP-1 medication.
          </h2>
          <Common.Alert type="warning" colorableBackground>
            Based on your history of pancreatitis or elevated blood lipase levels, you are not
            suitable for treatment with GLP-1 receptor agonists. Your safety is our number one
            priority and these medications have been associated, though rarely, with an increased
            risk of pancreatitis. Moreover, the symptoms you might experience from the medication,
            such as abdominal pain or nausea, could mask or be confused with a pancreatitis
            flare-up.
          </Common.Alert>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </>
      ) : (
        <>
          <Heading
            category="About you"
            title="Any history of pancreatitis (inflammation of the pancreas) or elevated blood lipase level(s)?"
          />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={value as boolean}
            onSelect={handleSelect}
          />
        </>
      )}
    </div>
  );
};

export default Pancreatitis;
