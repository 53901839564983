import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import useAnalytics from 'hooks/useAnalytics';
import usePartnerPatient from 'hooks/usePartnerPatient';
import { ACCOUNT_LEGAL_LINKS } from 'utils/constants';
import { PathName } from 'utils/enums';

import packageInfo from '../../../../../package.json';

const Legal = () => {
  useTitle('LifeMD - Legal');

  const isOptaviaPatient = !usePartnerPatient('Optavia');
  const logEvent = useAnalytics();

  const LINKS = [
    ...ACCOUNT_LEGAL_LINKS,
    {
      name: 'Medicaid Waiver',
      path: PathName.MedicaidWaiver,
      icon: 'folder' as IconProps['name']
    },
    ...(isOptaviaPatient
      ? [
          {
            name: 'HIPAA Marketing Agreement',
            path: PathName.HippaAgreement,
            icon: 'reports' as IconProps['name']
          }
        ]
      : [])
  ];

  return (
    <FadeWrapper className="flex flex-col gap-4 md:p-8">
      <NavLink className="flex items-center gap-3 max-md:hidden" to={PathName.About}>
        <Common.Icon name="arrow-left" />
        <h1 className="text-xl font-bold">Legal</h1>
      </NavLink>
      {LINKS.map((el) => (
        <NavLink
          className="flex w-full cursor-pointer items-center gap-2 py-4 text-left text-mBase font-semibold md:px-4 md:font-bold"
          key={el.path}
          target={ACCOUNT_LEGAL_LINKS.includes(el) ? '_blank' : undefined}
          to={el.path}
          onClick={() => logEvent(`my_account_${el.name}_mitem_click`)}
        >
          <Common.Icon className="size-5 text-gray-700" name={el.icon} />
          <span className="flex-1">{el.name}</span>
          <Common.Icon className="size-5 text-gray" name="arrow-right" />
        </NavLink>
      ))}
      <span className="mx-auto py-4 text-gray md:hidden">v{packageInfo.version}</span>
    </FadeWrapper>
  );
};
export default Legal;
