import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { AnimatePresence } from 'framer-motion';
import posthog from 'posthog-js';

import { usePatientLoginMutation, useVerifyUserTokenMutation } from 'services/auth/auth';
import { PatientLoginResProps, VerifyUserTokenResProps } from 'services/auth/auth.types';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';
import { GetMyAccountResProps } from 'services/myAccount/myAccount.types';

import { selectUser } from 'store';
import { clearUser, setToken, setUser } from 'store/user/userSlice';

import Loader from 'shared/Loader';

import { LINK_TO_POSTHOG } from 'constants/externalLinks';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { useDarkTheme } from 'hooks/useDarkTheme';
import { useLogoutOnIdle } from 'hooks/useLogoutOnIdle';
import { useRemoveQueryParams } from 'hooks/useRemoveQueryParams';
import { useRouteMatch } from 'hooks/useRouteMatch';
import SocketFrontDesk from 'socket/socketFrontDesk';
import SocketMessages from 'socket/socketMessages';
import { IS_LIVE } from 'utils/constants';
import {
  DeepLinkAction,
  InternalLinkAction,
  MessageTab,
  PathName,
  PlanCodes,
  WebViewPaths
} from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { ActionType } from './services/wm/wm.types';
import AppRouter from './router';
import { socketClose, socketInit } from './socket';

const App = () => {
  useLogoutOnIdle();
  const updateContext = useUnleashContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const { userId, accessToken } = useAppSelector(selectUser);
  const [patientLogin, { isLoading }] = usePatientLoginMutation();
  const [getMyAccount] = useLazyGetMyAccountQuery();
  const [getToken] = useVerifyUserTokenMutation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const [, removeQueryParams] = useRemoveQueryParams();

  const isCreateNewPassword = useRouteMatch(PathName.CreateNewPassword);
  const showOnlyLoader = isLoading || initialLoading || (accessToken && !userId);

  if (!accessToken && IS_LIVE) {
    posthog.init(import.meta.env.VITE_POSTHOG_API_KEY || '', {
      api_host: LINK_TO_POSTHOG
    });
    // posthog.identify(nanoid(), {});
  }

  const connectToSockets = (token: string) => {
    socketInit(SocketMessages, token);
    socketInit(SocketFrontDesk, token);
  };

  const handleGetMyAccountThen = (data: GetMyAccountResProps) => {
    const date = query.get('date');
    const action = query.get('action');
    const src = query.get('src');
    const chatId = query.get('chatId');
    const guideId = query.get('guideId');
    const orderId = query.get('orderId');
    const invoiceId = query.get('invoiceId');
    const type = query.get('type') || 'renewal';

    const deepLinkActions: { [key: string]: { pathname: PathName | string; search?: string } } = {
      [DeepLinkAction.DiscountCard]: {
        pathname: PathName.Prescriptions,
        search: 'tab=Discount card'
      },
      [DeepLinkAction.FrontDeskChannel]: {
        pathname: PathName.Messages,
        search: `tab=${MessageTab.FRONT_DESK}&chatId=${chatId}`
      },
      [DeepLinkAction.IntakeForm]: { pathname: PathName.IntakeForm },
      [DeepLinkAction.Labs]: { pathname: PathName.Labs },
      [DeepLinkAction.MessageChannel]: {
        pathname: PathName.Messages,
        search: `tab=${MessageTab.CARE_TEAM}&chatId=${chatId}`
      },
      [DeepLinkAction.PlanRecommendation]: { pathname: PathName.MigrateToPlan },
      [DeepLinkAction.UnpaidInvoice]: {
        pathname: PathName.UnpaidInvoice,
        search: `?invoiceId=${invoiceId}`
      },
      [DeepLinkAction.UpdateCreditCard]: { pathname: PathName.BillingDetails },
      [DeepLinkAction.WMAppointmentRequired]: { pathname: PathName.AppointmentRequired },
      [DeepLinkAction.WMGuide]: { pathname: `${PathName.WMArticle}/${guideId}` },
      [DeepLinkAction.WMPaywall]: {
        pathname: PathName.Dashboard,
        search: `action=${InternalLinkAction.AskToUpgradeToTheWM}`
      },
      [DeepLinkAction.WMSurvey]:
        data?.data.hadOrHasWeightManagement &&
        data?.data.activePlanCode !== PlanCodes.WeightManagementMembership
          ? {
              pathname: PathName.Dashboard,
              search: `action=${InternalLinkAction.AskToUpgradeToTheWM}`
            }
          : {
              pathname: [
                ActionType.renewal,
                ActionType.monthlyMedicationCheckIn,
                ActionType.weeklyMedicationCheckIn,
                ActionType.checkIn
              ].includes(type as ActionType)
                ? PathName.PrescriptionStatus
                : PathName.DeviceStatus,
              search: `orderId=${orderId}&type=${type}`
            },
      [DeepLinkAction.WaitingRoom]: { pathname: PathName.ZoomCall, search: `callId=${chatId}` }
    };

    const webViewPaths: { [key: string]: { pathname: PathName; search?: string } } = {
      [WebViewPaths.CreateAppointment]: {
        pathname: PathName.CreateAppointment,
        search: `src=appMobile${date ? `&date=${date}` : ''}`
      },
      [WebViewPaths.IntakeForm]: { pathname: PathName.IntakeForm, search: 'src=intakeForm' },
      [WebViewPaths.BillingPage]: { pathname: PathName.BillingDetails, search: `action=${action}` },
      [WebViewPaths.HealthProfile]: { pathname: PathName.HealthProfile },
      [WebViewPaths.Prescriptions]: {
        pathname: PathName.PrescriptionStatus,
        search: 'step=updateCard'
      },
      [WebViewPaths.CompleteAccount]: {
        pathname: PathName.CompleteAccount,
        search: `${date ? `date=${date}` : ''}`
      },
      [WebViewPaths.UpgradeToWM]: { pathname: PathName.MigrateToWM },
      [WebViewPaths.MigrateToTT]: { pathname: PathName.MigrateToTT, search: `s=why-tt` },
      [WebViewPaths.TirzepatideMigration]: { pathname: PathName.MoveFromTirzepatide }
    };

    if (action && deepLinkActions[action]) {
      return navigate(deepLinkActions[action]);
    }

    if (src && webViewPaths[src]) {
      return navigate(webViewPaths[src]);
    }
  };

  const handleUpdatedAccessToken = () => {
    const loginToken = query.get('login-token');
    if (accessToken && !loginToken) {
      connectToSockets(accessToken);
      getMyAccount().unwrap().then(handleGetMyAccountThen);
    } else {
      socketClose(SocketMessages);
      socketClose(SocketFrontDesk);
    }
  };

  const handlePatientLoginThen = (data: PatientLoginResProps) => {
    localStorage.setItem('logout-event', 'logout' + Math.random());
    const createAppointment = query.get('create-appointment');
    const staffId = query.get('staffId');
    dispatch(setUser({ ...data.data, profileImage: '' }));
    getMyAccount()
      .unwrap()
      .finally(() => connectToSockets(data.data.accessToken));
    sessionStorage.setItem('viewAsPatient', 'true');
    staffId && sessionStorage.setItem('staffId', staffId);
    navigate(
      createAppointment && JSON.parse(createAppointment)
        ? PathName.CreateAppointment
        : PathName.Dashboard
    );
  };

  const handleGetTokenThen = (res: VerifyUserTokenResProps) => {
    dispatch(clearUser());
    dispatch(setToken({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken }));
    // getMyAccount();
  };

  const handleQueryParams = () => {
    window.addEventListener('storage', (event) => {
      if (event.key == 'logout-event') {
        dispatch(clearUser());
      }
    });
    try {
      const accessTokenFromQuery = query.get('accessToken');
      if (accessTokenFromQuery) {
        dispatch(clearUser());
        dispatch(setUser({ accessToken: accessTokenFromQuery }));
      }

      const temporaryToken = query.get('token');
      if (temporaryToken) {
        if (isCreateNewPassword) {
          dispatch(clearUser());
          return;
        }
        if (accessToken) return;
        removeQueryParams('token');
        getToken({ disableActiveUsers: false, token: temporaryToken })
          .unwrap()
          .then(handleGetTokenThen)
          .catch((err) => {
            if (err.status === 401) {
              dispatch(clearUser());
            }
          });
      }

      const refetch = query.get('refetch') === 'true';
      if (refetch) {
        getMyAccount();
      }

      const loginToken = query.get('login-token');
      if (loginToken) {
        dispatch(clearUser());
        patientLogin({ loginToken })
          .unwrap()
          .then(handlePatientLoginThen)
          .catch(handleRequestCatch);
      }
    } finally {
      setInitialLoading(false);
    }
  };

  const handleUpdatedUserId = () => {
    userId && updateContext({ userId });
    userId ? datadogRum.setUser({ id: userId }) : datadogRum.clearUser();
  };

  useDarkTheme();

  useEffect(handleQueryParams, []);

  useEffect(handleUpdatedAccessToken, [accessToken]);

  useEffect(handleUpdatedUserId, [userId]);

  return (
    <>
      <ToastContainer className="min-w-full md:min-w-[45%]" position="top-center" />
      {showOnlyLoader ? (
        <Loader isVisible />
      ) : (
        <AnimatePresence>
          <AppRouter />
        </AnimatePresence>
      )}
    </>
  );
};

export default App;
