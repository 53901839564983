import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetSet, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import {
  useDeleteDocumentMutation,
  useLazyGetDocumentsQuery,
  useUploadDocumentsMutation
} from 'services/documents/documents';
import { GetDocumentsResProps } from 'services/documents/documents.types';
import { useAddInsuranceMutation, useUpdateInsuranceMutation } from 'services/insurance/insurance';

import FileZone from 'features/FileZone';
import { FileType } from 'features/FileZone/fileZone.types';
import InsuranceExamples from 'modals/InsuranceExamples';
import TextInput from 'shared/form/TextInput';
import { notifyError, notifyToast } from 'shared/Toast/Toast';

import useAnalytics from 'hooks/useAnalytics';
import { handleRequestCatch } from 'utils/helpers';
import { MEMBER_ID_REGEXP } from 'utils/regExp';

import { getDocumentIDs, setInitialDocsStatus } from './insurance.settings';
import { FormValues, InsuranceDocsProps, Props } from './insurance.types';

const Insurance: React.FC<Props> = ({
  onContinue,
  src,
  onCancel,
  continueBtnText = 'Continue',
  defaultFormValues,
  insuranceID,
  accessToken,
  loading = false,
  rank = 1
}) => {
  const logEvent = useAnalytics();
  const [getDocuments, { isLoading: isGettingDocuments }] = useLazyGetDocumentsQuery();
  const [uploadDocuments] = useUploadDocumentsMutation();
  const [deleteDocument, { isLoading: deleteDocumentsLoading }] = useDeleteDocumentMutation();
  const [addInsurance, { isLoading }] = useAddInsuranceMutation();
  const [updateInsurance, { isLoading: isUpdatingInsurance }] = useUpdateInsuranceMutation();

  const [isPrimaryHolderCheck, toggleCheck] = useToggle(true);
  const [showModal, toggle] = useToggle(false);
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const [filesStatus, setFilesStatus] = useGetSet<InsuranceDocsProps>({
    back: { fileName: '', filePath: '', fileStatus: 'initial' },
    front: { fileName: '', filePath: '', fileStatus: 'initial' }
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid },
    reset
  } = useForm<FormValues>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const handleClick = async (items: File[], t: string) => {
    const type = t as FileType;
    try {
      const images = items?.filter((item) =>
        ['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(item.type)
      );

      if (images.length !== 1) {
        return notifyToast('Please select one image');
      }

      let convertedFile = images[0];

      if (convertedFile.type === 'image/heic') {
        const heic2any = (await import('heic2any')).default;
        const imageBlob = await heic2any({
          blob: convertedFile,
          toType: 'image/jpeg'
        });

        // Create a new File object with the converted image
        convertedFile = new File(
          [imageBlob as Blob],
          convertedFile.name.replace('.heic', '.jpeg'),
          {
            type: 'image/jpeg'
          }
        );
      }

      if (convertedFile.size > 10000000) {
        throw new Error(
          `We apologize, but the image you've uploaded exceed the maximum allowable size 10MB. To continue, please consider resizing the image or selecting smaller one.`
        );
      }

      setFilesStatus({
        ...filesStatus(),
        [type]: { ...filesStatus()[type], fileStatus: 'processing' }
      });
      const formData = new FormData();
      formData.append('patientDocumentFile', convertedFile);
      formData.append('category', 'insurance');
      formData.append(
        'insuranceCard',
        JSON.stringify({
          rank,
          side: type === 'front' ? 1 : 2,
          ...(!!insuranceID && { insuranceId: insuranceID })
        })
      );
      const res = await uploadDocuments({ body: formData }).unwrap();
      setFilesStatus({
        ...filesStatus(),
        [type]: {
          _id: res.data._id,
          fileName: images[0].name,
          filePath: res.data.filePath,
          fileStatus: 'success'
        }
      });
    } catch (e) {
      notifyError((e as Error).message || "Can't upload file");
      setFilesStatus({
        ...filesStatus(),
        [type]: {
          _id: '',
          fileName: '',
          filePath: '',
          fileStatus: 'error'
        }
      });
    }
  };

  const onSubmit = () => {
    Cookies.set('insurance-primary-holder', isPrimaryHolderCheck ? '1' : '0');
    (insuranceID
      ? updateInsurance({ ...getValues(), _id: insuranceID })
      : addInsurance({
          hasInsurance: true,
          insurance: { ...getValues(), rank },
          insuranceDocuments: getDocumentIDs(filesStatus())
        })
    )
      .unwrap()
      .then(onContinue)
      .catch((e) => handleRequestCatch(e, "Can't update insurance, please try again later"));
  };

  const handleDelete = (type: FileType) => (id: string) => {
    logEvent('insurance_delete_doc_icon_click');
    setDocumentToDelete(id);
    deleteDocument({ documentId: id })
      .unwrap()
      .then(() => {
        setFilesStatus({
          ...filesStatus(),
          [type]: { fileName: '', filePath: '', fileStatus: 'initial' }
        });
      })
      .finally(() => {
        setDocumentToDelete(null);
      });
  };

  const handleGetDocuments = ({ data }: GetDocumentsResProps) => {
    const initialDocs = setInitialDocsStatus(filesStatus(), data, insuranceID);
    setFilesStatus(initialDocs);
  };

  const handleClickSeeExamples = () => {
    logEvent('insurance_example_btn_click');
    toggle();
  };

  const onInit = () => {
    if (defaultFormValues) {
      reset(defaultFormValues);
    }
    const isPrimaryHolder = Cookies.get('insurance-primary-holder');
    toggleCheck(isPrimaryHolder !== '0');
    getDocuments({ accessToken, category: 'insurance', pageNo: 0 })
      .unwrap()
      .then(handleGetDocuments)
      .catch(handleRequestCatch);
  };

  const handleClickCancel = () => {
    logEvent('insurance_cancel_btn_click');
    onCancel?.();
  };

  const isDocsUploaded =
    filesStatus().back.fileStatus === 'success' && filesStatus().front.fileStatus === 'success';

  useEffect(onInit, []);

  const headingClassName = 'font-bold text-primary-700';

  return (
    <div>
      <InsuranceExamples isOpen={showModal} onClose={toggle} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={classNames(
            'relative mx-auto flex flex-col gap-8 max-md:h-full md:bg-white md:p-8 rounded-t-2xl',
            { 'max-w-[500px] md:px-0': src === 'my-chart' }
          )}
        >
          <Common.Alert type="info" colorableBackground>
            {src === 'my-chart'
              ? 'If you have insurance we can use the details to calculate your prescription benefits. Your insurance will not be billed for appointments on the LifeMD platform.'
              : src === 'migrate-to-wm'
                ? 'We need your insurance details to calculate your prescription benefits. This insurance will not be billed for appointments on the LifeMD platform.'
                : 'In order to check your insurance coverage, please provide your insurance information. LifeMD will not bill your insurance.'}
          </Common.Alert>
          <div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <h3 className={headingClassName}>Insurance card photos</h3>
                <Common.Button
                  className="max-md:hidden"
                  color="white"
                  dataTestId="see_exapmles_btn"
                  type="button"
                  onClick={handleClickSeeExamples}
                >
                  See examples
                </Common.Button>
              </div>
              <div className="flex justify-stretch gap-4">
                <div className="w-full md:max-w-[50%]" data-testid="file_zone_front">
                  <FileZone
                    accept="image/png, image/jpeg, image/jpg, .heic"
                    loading={
                      (deleteDocumentsLoading && documentToDelete === filesStatus().front._id) ||
                      isGettingDocuments
                    }
                    status={filesStatus().front}
                    type="front"
                    onDelete={handleDelete('front')}
                    onSelect={handleClick}
                  />
                </div>
                <div className="w-full md:max-w-[50%]" data-testid="file_zone_back">
                  <FileZone
                    accept="image/png, image/jpeg, image/jpg, .heic"
                    loading={
                      (deleteDocumentsLoading && documentToDelete === filesStatus().back._id) ||
                      isGettingDocuments
                    }
                    status={filesStatus().back}
                    type="back"
                    onDelete={handleDelete('back')}
                    onSelect={handleClick}
                  />
                </div>
              </div>
              <Common.Button
                className="mx-auto md:hidden"
                color="white"
                type="button"
                onClick={toggle}
              >
                See examples
              </Common.Button>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className={headingClassName}>Insurance details</h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              <div className="md:col-span-3">
                <TextInput
                  control={control}
                  dataTestId="member_id_input"
                  disabled={isLoading}
                  invalidErrorMsg="Must be non-empty and using only letters, numbers, - or _."
                  label="Member ID"
                  maxLength={20}
                  name="memberId"
                  placeholder="12345678900"
                  regExp={MEMBER_ID_REGEXP}
                  requiredErrorMsg="Field is required"
                />
              </div>
              <div>
                <TextInput
                  control={control}
                  dataTestId="rx_bin_input"
                  disabled={isLoading}
                  invalidErrorMsg="Must be non-empty and using only letters, numbers, - or _."
                  label="RX BIN"
                  name="rxBIN"
                  placeholder="0012345"
                  regExp={MEMBER_ID_REGEXP}
                  requiredErrorMsg="Field is required"
                />
              </div>
              <div>
                <TextInput
                  control={control}
                  dataTestId="rx_group_input"
                  disabled={isLoading}
                  invalidErrorMsg="RX GROUP is invalid"
                  isRequired={false}
                  label="RX Group"
                  name="rxGroupId"
                  placeholder="A1AA"
                  regExp={MEMBER_ID_REGEXP}
                  requiredErrorMsg="Field is required"
                />
              </div>
              <div>
                <TextInput
                  control={control}
                  dataTestId="pcn_input"
                  disabled={isLoading}
                  invalidErrorMsg="PCN is invalid"
                  isRequired={false}
                  label="PCN"
                  name="rxPCN"
                  placeholder="A1"
                  regExp={MEMBER_ID_REGEXP}
                  requiredErrorMsg="Field is required"
                />
              </div>
            </div>
          </div>
          <Common.Checkbox checked={isPrimaryHolderCheck} color="blue" onChange={toggleCheck}>
            <span className="font-bold text-gray-700">
              I am the primary account holder for this plan.
            </span>
          </Common.Checkbox>
        </div>
        <div
          className={classNames(
            'flex flex-wrap justify-center gap-4 rounded-b-2xl max-md:mt-4 md:bg-white md:px-8 md:py-6',
            { 'border-gray-200 md:border-t': src === 'my-chart' }
          )}
        >
          <Common.Button
            color="blue"
            disabled={!isValid || !isDocsUploaded || isUpdatingInsurance || isLoading || loading}
            isLoading={isLoading || isUpdatingInsurance || loading}
            fullWidthOnMobile
          >
            {continueBtnText}
          </Common.Button>
          {!!onCancel && (
            <Common.Button
              color="white-alt"
              type="button"
              fullWidthOnMobile
              onClick={handleClickCancel}
            >
              Cancel
            </Common.Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Insurance;
