import useWeightManagement from 'hooks/useWeightManagement';

import CareTypeCard from '../CareTypeCard';

import { getBuildCards } from './careTypes.settings';
import { CareTypesProps } from './careTypes.types';

const CareTypes: React.FC<CareTypesProps> = ({ onSelect }) => {
  const { isWeightManagement } = useWeightManagement();
  const cards = getBuildCards(onSelect, isWeightManagement);

  const getClassNames = (title: string) => {
    if (title === 'Talk to a doctor') {
      return 'col-span-2';
    }
    if (title === 'Prescriptions' && cards.every((c) => c.title !== 'Labs')) {
      return 'col-span-2';
    }
  };
  return (
    <div className="grid gap-4 max-md:grid-cols-2 md:grid-cols-4">
      {cards.map((card) => (
        <CareTypeCard className={getClassNames(card.title)} key={card.title} {...card} />
      ))}
    </div>
  );
};

export default CareTypes;
