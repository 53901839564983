import { IconProps } from '@thecvlb/design-system/lib/src/common/Icon';

import { ValidCouponCodes } from 'store/signup/signup.types';

import { WM_FLOWS } from 'constants/onboarding';
import { ReceiverDetailsProps } from 'contexts/messagesContext/messagesContext.types';

import { LINK_TO_ACCESSIBILITY, SIGNUP_CONFIRM_LINKS } from '../constants/externalLinks';

import { CompleteFreemiumSteps, FlowTypes, PathName, PlanCodes, ScreenSizes } from './enums';

import UserAvatar from 'assets/icons/doc-avatar.svg';
import ChatImage from 'assets/images/auth/sign-in/login-bg-2.webp';
import ProvidersRowsImage from 'assets/images/auth/sign-in/login-bg-3.webp';
import YourPrescriptionImage from 'assets/images/auth/sign-in/login-bg-4.webp';
import TreatmentsImage from 'assets/images/auth/sign-in/login-bg-5.webp';
import MobileDemoImage from 'assets/images/auth/sign-in/login-bg-6.webp';

export const IS_LIVE = import.meta.env.VITE_ENV === 'production';

export const PATIENTS_AGE_LIMITS = {
  MAX: 63,
  MIN: 18
};

export const RED_FLAGS = [
  'Chest pain',
  'Focal weakness in arm or legs',
  'Trouble speaking',
  'Syncope/passing out',
  'Shortness of breath',
  'Acute swelling of legs',
  'Irregular heartbeat',
  'Vomiting blood',
  'Bloody diarrhea',
  'Abdominal/pelvic pain with fever',
  'Significant trauma',
  'Alcohol/drug abuse',
  'Hallucinations (hearing voices, seeing things that are not seen by others)',
  'Any thoughts of harming self or others',
  'Acute scrotal pain',
  'Rash with a fever',
  'Pregnancy complications'
];

export const ASPECT_RATIO = 16 / 9;

export const LOS_ANGELES_COORDS = {
  lat: 34.0522,
  lng: -118.2437
};

export const SIGNUP_COUPON_CODES: {
  [key in ValidCouponCodes]:
    | string
    | {
        discount: {
          unit: 'absolute' | 'percentage' | 'months' | 'advanced';
          value: string;
        };
        flowsAllowed: 'All' | FlowTypes[];
        hasSubCodes: boolean;
        periodsAllowed: 'All' | number[];
        plansAllowed: 'All' | { code: string; plan: PlanCodes }[];
      };
} = {
  '4health25': {
    discount: {
      unit: 'percentage',
      value: '25%'
    },
    flowsAllowed: 'All',
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: 'All'
  },
  '4health50': {
    discount: {
      unit: 'percentage',
      value: '50%'
    },
    flowsAllowed: 'All',
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: 'All'
  },
  '4health75': {
    discount: {
      unit: 'percentage',
      value: '75%'
    },
    flowsAllowed: 'All',
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: 'All'
  },
  GLP150OFF: {
    discount: {
      unit: 'percentage',
      value: '50%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP150OFF', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP250OFF: {
    discount: {
      unit: 'percentage',
      value: '25%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP250OFF', plan: PlanCodes.WeightManagementMembership }]
  },
  app25: {
    discount: {
      unit: 'advanced',
      value: '25%offOnAppointment'
    },
    flowsAllowed: 'All',
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [
      { code: 'app25', plan: PlanCodes.UrgentCare },
      { code: 'app25', plan: PlanCodes.FlexCare },
      { code: 'app25', plan: PlanCodes.LifeMDMembership }
    ]
  },
  cleared50: {
    discount: {
      unit: 'advanced',
      value: '50%offOnAppointmentOrFirstMonth'
    },
    flowsAllowed: 'All',
    hasSubCodes: true,
    periodsAllowed: 'All',
    plansAllowed: [
      { code: 'firstmonth50tc', plan: PlanCodes.TotalCareMembership },
      { code: 'firstmonth50fc', plan: PlanCodes.FlexCare },
      { code: 'firstmonth50cc', plan: PlanCodes.ConciergeMembership }
    ]
  },
  clearedcovered: {
    discount: {
      unit: 'advanced',
      value: '100%offOnAppointmentOrFirstMonth'
    },
    flowsAllowed: 'All',
    hasSubCodes: true,
    periodsAllowed: 'All',
    plansAllowed: [
      { code: 'firstmonthfreetotal', plan: PlanCodes.TotalCareMembership },
      { code: 'firstmonthfreeflex', plan: PlanCodes.FlexCare },
      { code: 'firstmonthfreeconcierge', plan: PlanCodes.ConciergeMembership }
    ]
  },
  firstmonthfree: {
    discount: {
      unit: 'months',
      value: '1'
    },
    flowsAllowed: 'All',
    hasSubCodes: true,
    periodsAllowed: [3, 12],
    plansAllowed: [
      { code: 'firstmonthfreetotal', plan: PlanCodes.TotalCareMembership },
      { code: 'firstmonthfreeconcierge', plan: PlanCodes.ConciergeMembership }
    ]
  },
  firstmonthfreeconcierge: {
    discount: {
      unit: 'months',
      value: '1'
    },
    flowsAllowed: 'All',
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'firstmonthfreeconcierge', plan: PlanCodes.ConciergeMembership }]
  },
  firstmonthfreetotal: {
    discount: {
      unit: 'months',
      value: '1'
    },
    flowsAllowed: 'All',
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'firstmonthfreetotal', plan: PlanCodes.TotalCareMembership }]
  },
  freebct: 'AT-HOME BREAST CANCER DETECTION',
  freeccs: 'FREE TEST KIT APPLIED',
  rex50: {
    discount: {
      unit: 'advanced',
      value: '50%offOnAppointmentOrFirstMonth'
    },
    flowsAllowed: 'All',
    hasSubCodes: true,
    periodsAllowed: 'All',
    plansAllowed: [
      { code: 'firstmonth50tc', plan: PlanCodes.TotalCareMembership },
      { code: 'firstmonth50fc', plan: PlanCodes.FlexCare },
      { code: 'firstmonth50cc', plan: PlanCodes.ConciergeMembership }
    ]
  },
  LFMD30OFF: {
    discount: {
      unit: 'absolute',
      value: '30'
    },
    flowsAllowed: [FlowTypes.TripleTherapy],
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: 'All'
  },
  GLP115REC: {
    discount: {
      unit: 'percentage',
      value: '15%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP115REC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP125REC: {
    discount: {
      unit: 'percentage',
      value: '25%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP125REC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP133REC: {
    discount: {
      unit: 'percentage',
      value: '33%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP133REC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP137REC: {
    discount: {
      unit: 'percentage',
      value: '37%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP137REC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP150REC: {
    discount: {
      unit: 'percentage',
      value: '50%'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP150REC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP100FLATREC: {
    discount: {
      unit: 'absolute',
      value: '100'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP100FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP1F137REC: {
    discount: {
      unit: 'absolute',
      value: '150'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP150FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP200FLATREC: {
    discount: {
      unit: 'absolute',
      value: '200'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP200FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP398FLATREC: {
    discount: {
      unit: 'absolute',
      value: '398'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP398FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP498FLATREC: {
    discount: {
      unit: 'absolute',
      value: '498'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP498FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLPBH59FLATREC: {
    discount: {
      unit: 'absolute',
      value: '59'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLPBH59FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLP74FLATREC: {
    discount: {
      unit: 'absolute',
      value: '74'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLP74FLATREC', plan: PlanCodes.WeightManagementMembership }]
  },
  GLPBH222FLATREC: {
    discount: {
      unit: 'absolute',
      value: '222'
    },
    flowsAllowed: WM_FLOWS,
    hasSubCodes: false,
    periodsAllowed: 'All',
    plansAllowed: [{ code: 'GLPBH222FLATREC', plan: PlanCodes.WeightManagementMembership }]
  }
};

export const REQUESTED_LAB_LIST = [
  'Make your appointment with Quest.',
  'Visit the lab and present a valid form of ID to get up to 95% savings.',
  'Complete your lab tests.',
  'Get notified in your LifeMD patient portal when your lab results are ready.',
  'Meet with your provider online to discuss your results.'
];

export const TT_LAB_STEPS = [
  'Make your appointment with Quest.',
  'Complete your lab tests. ',
  'Get notified in your LifeMD patient portal when your lab results are ready.'
];

export const TRIGGER_KEYWORDS = [
  'pounds',
  'ozempic',
  'ozempik',
  'mounjaro',
  'moonjaro',
  'wegovy',
  'wigove',
  'wegovee',
  'wegove',
  'wegovee',
  'semaglutide',
  'semiglutide',
  'tirzepatide',
  'terzepitide',
  'tirzepitide',
  'weight',
  'overweight',
  'obese',
  'obees',
  'fat',
  'apetite',
  'glp',
  'glp1',
  'glp-1'
];

export const FEATURES_LIST: {
  'lifemd-membership': unknown;
  title: string;
  'unlimited-membership': unknown;
}[] = [
  { 'lifemd-membership': 15, title: 'Membership price', 'unlimited-membership': 99 },
  { 'lifemd-membership': true, title: 'No insurance required', 'unlimited-membership': true },
  {
    'lifemd-membership': false,
    title: 'Unlimited no cost appointments',
    'unlimited-membership': true
  },
  {
    'lifemd-membership': false,
    title: 'Personalized onboarding during first appointment',
    'unlimited-membership': true
  },
  {
    'lifemd-membership': false,
    title: 'Address more than 1 health issue in an appointment',
    'unlimited-membership': true
  },
  {
    'lifemd-membership': false,
    title: 'Message with your provider about more than 1 issue',
    'unlimited-membership': true
  },
  {
    'lifemd-membership': true,
    title: '24/7 messaging with your medical team',
    'unlimited-membership': true
  },
  {
    'lifemd-membership': true,
    title: 'Prescriptions and refills**',
    'unlimited-membership': true
  },
  {
    'lifemd-membership': true,
    title: 'Lab orders • Up to 95% OFF!',
    'unlimited-membership': true
  }
];

export const MAP_STYLES = [
  {
    elementType: 'all',
    featureType: 'landscape',
    stylers: [
      {
        color: '#f3f6f6'
      }
    ]
  },
  {
    elementType: 'all',
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road',
    stylers: [
      {
        color: '#d3dfdf'
      },
      {
        weight: '1.10'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    elementType: 'labels',
    featureType: 'road.highway',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'water',
    stylers: [
      {
        color: '#dde5e6'
      }
    ]
  }
];

export const MY_ACCOUNT_TABS: {
  label: string;
  value: { icon: IconProps['name']; path: string };
}[] = [
  {
    label: 'Account information',
    value: { icon: 'patient-filled', path: PathName.AccountInformation }
  },
  { label: 'Change password', value: { icon: 'lock', path: PathName.ChangePassword } },
  { label: 'Insurance', value: { icon: 'document', path: PathName.Insurance } },
  { label: 'Notifications', value: { icon: 'bell', path: PathName.NotificationsSettings } },
  { label: 'Billing & card', value: { icon: 'checkout', path: PathName.BillingDetails } },
  { label: 'About', value: { icon: 'info', path: PathName.About } }
];
export const WEIGHT_TABS = [
  {
    label: 'All'
  },
  { label: 'Week' },
  { label: 'Month' },
  { label: 'Year' }
];

export const APPOINTMENTS_TABS = [{ label: 'Upcoming' }, { label: 'History' }];

export const UPGRADE_TO_ANNUAL_SHOWN_COOKIE = 'is-upgrade-to-annual-shown';

export const DEFAULT_DOM_RECT: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  toJSON: () => '',
  top: 0,
  width: 0,
  x: 0,
  y: 0
};

export const DEFAULT_MESSAGE = {
  _id: '',
  buttons: [],
  channelId: '',
  message: 'Welcome to LifeMD Messages! Use this space for health related questions.',
  messageStatus: {
    seen: null,
    sent: new Date()
  },
  receiverDetails: [{} as ReceiverDetailsProps],
  senderDetails: {
    email: '',
    id: '',
    name: 'LifeMD',
    profileImage: UserAvatar,
    status: '',
    userType: {
      name: '',
      shortCode: ''
    }
  }
};

export const ACCOUNT_SETTINGS_LINKS: { icon: IconProps['name']; name: string; path: PathName }[] = [
  { icon: 'patient-filled', name: 'Account information', path: PathName.AccountInformation },
  { icon: 'lock', name: 'Change password', path: PathName.ChangePassword },
  { icon: 'bell', name: 'Notifications', path: PathName.NotificationsSettings },
  { icon: 'document', name: 'Insurance', path: PathName.Insurance },
  { icon: 'checkout', name: 'Billing & card', path: PathName.BillingDetails }
];
export const ACCOUNT_ABOUT_LINKS: { icon: IconProps['name']; name: string; path: string }[] = [
  {
    icon: 'lock',
    name: 'Legal',
    path: PathName.Legal
  },
  {
    icon: 'accessibility',
    name: 'Accessibility',
    path: LINK_TO_ACCESSIBILITY
  }
];
export const ACCOUNT_LEGAL_LINKS: { icon: IconProps['name']; name: string; path: string }[] = [
  { icon: 'lock', name: 'Privacy policy', path: SIGNUP_CONFIRM_LINKS.privacy },
  { icon: 'document', name: 'Terms', path: SIGNUP_CONFIRM_LINKS.terms },
  {
    icon: 'pencil',
    name: 'Consent to Telehealth',
    path: SIGNUP_CONFIRM_LINKS.telehealth
  }
];

export const COMPLETE_ACCOUNT_STEPS: CompleteFreemiumSteps[] = [
  CompleteFreemiumSteps.Intro,
  CompleteFreemiumSteps.QuickCheck,
  CompleteFreemiumSteps.SelectPlan,
  CompleteFreemiumSteps.BookAppointment,
  CompleteFreemiumSteps.AppointmentDetails,
  CompleteFreemiumSteps.AppointmentFiles,
  CompleteFreemiumSteps.CreateUser,
  CompleteFreemiumSteps.Checkout,
  CompleteFreemiumSteps.ConfirmedAppointment,
  CompleteFreemiumSteps.IntakeForm
];

export const DOC_EXTENSIONS = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];

export const TAILWIND_BREAKPOINTS: { [key in ScreenSizes]: number } = {
  '2xl': 1536,
  lg: 1024,
  md: 768,
  sm: 640,
  xl: 1280,
  xs: 0
};

export const NEW_MEMBERSHIP_PLANS = [
  PlanCodes.FlexCare,
  PlanCodes.TotalCareMembership,
  PlanCodes.ConciergeMembership
];

export const UTI_FLOW_MEMBERSHIP_PLANS = [PlanCodes.FlexCare, PlanCodes.TotalCareMembership];

export const PARTNERSHIP_MODAL_FLOW = [FlowTypes.HealthWarehouseFlow, FlowTypes.HRTClubFlow];

export const ALT_INSURANCE_PLANS = [PlanCodes.FlexCare, PlanCodes.UrgentCare];

export const SUPPORT_PHONE_NUMBER = {
  link: 'tel:8008521575',
  text: '(800) 852-1575'
};

export const DUMMY_REVIEWS = [
  {
    rating: 5,
    text: 'Very knowledgeable. Personable. Made me feel comfortable about getting in control of my health when I was feeling embarrassed about neglecting it for some time.'
  },
  {
    rating: 5,
    text: 'Very nice, straightforward, and thorough. Surpassed my expectations for my first virtual health appointment.'
  },
  {
    rating: 5,
    text: 'Very polite and informative. Wants to make sure the patient has plenty of opportunities to discuss how they feel and take their opinions into account. Great experience!'
  },
  {
    rating: 5,
    text: 'I did not know what to expect as a first time telemedicine user. The process was simple and I felt like the Dr took ample time to get to know me and my symptoms. I will definitely use again and recommend to others!'
  },
  {
    rating: 5,
    text: 'Thorough Doctor. Analyzed my risk factors and provided covid treatment without a 4hr wait at a local urgent care.'
  },
  {
    rating: 5,
    text: 'Listened to all my symptoms and prescribed what I needed. Easy and swift way to solve my health issue.'
  },
  {
    rating: 5,
    text: 'The most incredible doctor I have ever been associated with. Very understanding and knowledgeable! Incredible appointment thank you so much!'
  },
  {
    rating: 5,
    text: 'Very easy, pleasurable experience with both the pre-call and call with the doctor. Asked great questions, cared about my health, listened and conversation was just as easy as if in an office.'
  },
  {
    rating: 5,
    text: 'Professional, friendly and patient. Wonderful experience. So grateful for this service, as my own physician’s office told me to call back Monday. '
  },
  {
    rating: 5,
    text: 'Amazing experience and very helpful in getting medicine needed to feel better without sitting in doctors office with extreme wait times.'
  }
];

export const MOCK_GUIDES = [
  {
    description: 'Here’s a brief summary of this link. Lorem ipsum dolor sit amet, consectet.',
    id: 1,
    question: 'How do I use my GLP-1?',
    thumb: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/TearsOfSteel.jpg',
    title: 'How to use injector',
    type: 'video',
    url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4'
  },
  {
    description: 'Here’s a brief summary of this link. Lorem ipsum dolor sit amet, consectet.',
    id: 2,
    question: 'What about side effects?',
    thumb: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/TearsOfSteel.jpg',
    title: 'What to expect with your GLP-1',
    type: 'video',
    url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4'
  },
  {
    description: 'Here’s a brief summary of this link. Lorem ipsum dolor sit amet, consectet.',
    id: 3,
    question: 'I want to meet with my provider.',
    thumb: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/TearsOfSteel.jpg',
    title: 'Meet with your provider',
    type: 'video',
    url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4'
  }
];

export const WM_INSURANCE_DUMMY_ADVANTAGES = [
  'Online and in-app messaging with your dedicated care team',
  'Appointment booking online',
  'Simple prescription renewals',
  'LifeMD program discounts',
  'Access to health metrics to track your progress'
];

export const BLUE_LINE_ADVANTAGES = [
  'Access to GLP-1 prescription from a U.S.-licensed provider',
  'Complimentary metabolic testing',
  'LifeMD-dedicated weight loss guidance',
  'Ongoing support through your goal weight'
];

export const WM_DUMMY_ADVANTAGES = [
  'Access to GLP-1 prescription from a U.S.-licensed provider',
  'Complimentary metabolic lab testing',
  'Insurance coverage assistance for GLP-1 medications like Ozempic® and Wegovy®',
  'Ongoing clinical guidance and support'
];

export const OPTAVIA_DUMMY_ADVANTAGES = [
  'Access to GLP-1 prescriptions from a provider.',
  'Complimentary lab work and weight loss expert guidance.',
  'Ongoing support throughout your weight loss journey.'
];

export const FAQ_WM = [
  {
    id: '1',
    text: 'GLP-1 medications work by mimicking the activity of a naturally occurring hormone in the body called GLP-1, which is released by your small intestine minutes after you eat. GLP-1 medications bind to GLP-1 receptors in the body, causing the stomach to empty at a slower rate. They also regulate the brain’s hunger signals. As a result, you feel fuller faster, end up eating less, and lose more weight.',
    title: 'How do GLP-1s work for weight loss?'
  },
  {
    id: '2',
    text: 'In addition to access to prescription GLP-1 medication, the LifeMD Weight Management Program provides a tailored approach that goes beyond the typical weight loss offerings. You’ll receive medically-backed guidance from your care team throughout your entire weight loss journey.',
    title:
      'What makes the LifeMD Weight Management Program different from other Weight Management programs?'
  },
  {
    id: '3',
    text: 'When you enroll in the LifeMD Weight Management Program, you’ll gain access to a team of qualified healthcare professionals: board-certified doctors and nurse practitioners who are not only experienced in the field of Weight Management. Throughout your journey, you’ll receive personalized guidance and attentive monitoring so you can get results you’ll love.',
    title: 'Who will be on my care team and supporting me throughout the process?'
  },
  {
    id: '4',
    text: 'Yes! Everything – from your initial visit, to support provided by a team of clinicians, to ongoing check-ins – adds up to comprehensive care from the comfort of your home where you’re truly set up for weight loss success.',
    title: 'Is the LifeMD Weight Management Program completely online?'
  },
  {
    id: '5',
    text: 'The cost of GLP-1 medication is not included in the LifeMD Weight Management Program. However, LifeMD Insurance Assistance will work hands-on to get you the absolute lowest price for your medication.',
    title: 'Is GLP-1 medication included in the cost of the plan?'
  }
];

export const LOGIN_PREVIEW_SLIDES = [
  {
    imageAsBackground: true,
    text: 'Take the complication out of healthcare. Getting answers and treatments has never been easier or more affordable.',
    title: 'Elevate Your Healthcare.'
  },
  {
    imageClassName: 'h-[45dvh]',
    imageUrl: ChatImage,
    text: 'Now you can skip the waiting rooms and get the expert medical guidance you need, 24/7.',
    title: 'Around The Clock Care'
  },
  {
    className: 'pt-4',
    imageClassName: 'top-0',
    imageUrl: ProvidersRowsImage,
    text: 'No matter where you are, feel confident that your medical team is ready to help you feel your best.',
    title: 'Meet Your Medical Team'
  },
  {
    imageClassName: 'max-w-[280px] h-[45dvh]',
    imageUrl: YourPrescriptionImage,
    text: 'Hassle-free prescriptions. Plus, use your free prescription discount card to save up to 92% on your medication!',
    title: 'Prescriptions Made Easy'
  },
  {
    imageClassName: 'max-w-[280px] h-[45dvh]',
    imageUrl: TreatmentsImage,
    text: 'Address your primary, urgent, and chronic health needs from the comfort of your own home.',
    title: 'Skip The Drive'
  },
  {
    imageClassName: 'h-[45dvh]',
    imageUrl: MobileDemoImage,
    text: 'Just one app to view your health information, schedule appointments, and manage your prescriptions and more.',
    title: 'Your Health At Your Fingertips'
  }
];
