import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import HTMLReactParser from 'html-react-parser';

import { useGetSignableDocumentsQuery, useSignDocumentMutation } from 'services/general/general';

import { selectUser } from 'store';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import { notifyToast } from 'shared/Toast/Toast';

import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

const HippaAgreement = () => {
  useTitle('LifeMD - Hippa Agreement');

  const navigate = useNavigate();
  const { accessToken } = useAppSelector(selectUser);
  const { data, isFetching } = useGetSignableDocumentsQuery();
  const [signDocument, { isLoading }] = useSignDocumentMutation();

  const [isAgreed, toggleIsAgreed] = useToggle(false);

  const { documentId, html } =
    data?.data.find((item) => item.type === 'medicaid-waiver-terms') || {};

  const unSignDocument = () => {};

  const displayToast = () => {
    notifyToast(
      <span>
        <b>HIPAA Marketing Agreement</b> preferences changed.
      </span>,
      {
        closeButton: false
      },
      unSignDocument,
      'UNDO'
    );
  };

  const handleClickContinue = () => {
    if (!documentId) return;
    signDocument({ documentId, accessToken })
      .unwrap()
      .then(() => navigate(-1))
      .then(displayToast);
  };

  return (
    <FadeWrapper className="flex flex-col gap-4 md:p-8">
      <Loader isVisible={isFetching} />
      <NavLink className="flex items-center gap-3 max-md:hidden" to={PathName.Legal}>
        <Common.Icon name="arrow-left" />
        <h1 className="text-xl font-bold">HIPAA Marketing Agreement</h1>
      </NavLink>
      <div className="mx-auto flex w-full max-w-[500px] flex-col gap-8">
        <div className="text-primary-700" data-testid="agreement_block">
          {HTMLReactParser(html || '')}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 py-6">
            <p className="text-mXs font-semibold text-primary-700">Agreed on 11/12/2024 by</p>
            <p className="text-gray">Evan Bates</p>
          </div>
          <Common.Checkbox
            checked={isAgreed}
            color="blue"
            disabled={isFetching}
            onChange={toggleIsAgreed}
          >
            I acknowledge and agree to the statements written above.
          </Common.Checkbox>
          <Common.Button
            className="mx-auto"
            color="blue"
            dataTestId="continue_btn"
            disabled={!isAgreed || isFetching}
            isLoading={isLoading}
            fullWidthOnMobile
            onClick={handleClickContinue}
          >
            Save changes
          </Common.Button>
        </div>
      </div>
    </FadeWrapper>
  );
};
export default HippaAgreement;
