import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';

import { DEFAULT_APPOINTMENT_TYPE } from 'constants/defaults';

import { setAppointmentOptions } from './appointmentTypesPicker.settings';
import { AppointmentTypesPickerProps } from './appointmentTypesPicker.types';

const AppointmentTypesPicker: React.FC<AppointmentTypesPickerProps> = ({
  category,
  appointmentTypes,
  onClickNext
}) => {
  const [activeItem, setActiveItem] = useState<string>();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');

  const appointmentOptions = [
    ...setAppointmentOptions(!showMore, appointmentTypes, searchKey),
    ...(!showMore ? [{ label: 'Something else', value: 'other', data: null }] : [])
  ];

  const handleOnSelect = (value: string) => {
    setActiveItem(value);

    if (value === 'other') {
      setShowMore(true);
    } else {
      const selectedOption = appointmentOptions.find((appt) => appt.value === value);
      !!selectedOption?.data && onClickNext(selectedOption.data);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const defaulAppointmentType = appointmentOptions.find(
    (appt) => appt.label === DEFAULT_APPOINTMENT_TYPE
  );

  const redirectToDetailsScreen = () => {
    defaulAppointmentType?.data && onClickNext(defaulAppointmentType.data);
  };

  return (
    <div className="flex flex-col gap-6">
      {!showMore && (
        <div className="flex flex-col gap-2">
          <Common.ColorTag color="primary" icon="check" text={category} />
          <h1 className="text-m2xl font-bold text-primary-700">
            What would you like to be seen for?
          </h1>
        </div>
      )}

      {showMore && !!defaulAppointmentType && (
        <>
          <Common.SearchInput
            className="mt-0.5"
            placeholder="Find an appointment type..."
            onChange={handleSearch}
          />
          <div className="flex items-center justify-center gap-2 align-middle">
            <span className="text-mSm font-semibold">Not on this list?</span>
            <Common.Button color="white-alt" size="sm" onClick={redirectToDetailsScreen}>
              Be seen for something else
            </Common.Button>
          </div>
        </>
      )}

      <RadioGroup items={appointmentOptions} selectedItem={activeItem} onSelect={handleOnSelect} />
    </div>
  );
};

export default AppointmentTypesPicker;
