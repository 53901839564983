import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { DetailsForProvidersProps } from './detailsForProvider.types';

const DetailsForProviders: React.FC<DetailsForProvidersProps> = ({
  onClickNext,
  appointmentDescription,
  category
}) => {
  const [details, setDetails] = useState(appointmentDescription);

  return (
    <div className="flex h-full grow flex-col justify-between">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Common.ColorTag color="primary" icon="check" text={category} />
          <h1 className="text-m2xl font-bold text-primary-700">
            Please add details for your provider.
          </h1>
        </div>
        <Common.TextArea
          className="w-full"
          dataTestId="details_textArea"
          placeholder="What brings you in today?"
          rows={3}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
      </div>
      <Common.Button
        className="mx-auto mt-4"
        color="blue"
        dataTestId="continue_btn"
        fullWidthOnMobile
        onClick={() => onClickNext(details)}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default DetailsForProviders;
