import { Common } from '@thecvlb/design-system';

import { useGetGlobalMessagesQuery } from 'services/general/general';

import { selectLookup, selectUser } from 'store';

import RadioGroup from 'shared/RadioGroup';

import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';

import { QualifiedForAsyncProps } from './qualifiedForAsync.types';
const QualifiedForAsync: React.FC<QualifiedForAsyncProps> = ({ category, onSelect }) => {
  const { data } = useGetGlobalMessagesQuery();

  const { activePlanId, activePricePoint, isFirstAppointmentCompleted } =
    useAppSelector(selectUser);

  const { isWeightManagement } = useWeightManagement();
  const { membershipPlans } = useAppSelector(selectLookup);

  const currentPP = membershipPlans
    .find((plan) => plan._id === activePlanId)
    ?.pricePoints?.find((pp) => pp.planPricePointId === activePricePoint);

  const getSubLabel = (price: number) => (
    <p className="text-sm text-primary-400">
      <span className={isWeightManagement ? '' : 'text-primary-200 line-through'}>${price}</span>{' '}
      {!isWeightManagement && <span>(Insurance accepted)</span>}
    </p>
  );

  const OPTIONS = [
    {
      label: data?.messageAppointmentText || '',
      subLabel: getSubLabel(+(currentPP?.asyncAppointmentCost || 0)),
      description: 'Available within 3 hrs',
      value: true
    },
    {
      label: data?.videoAppointmentText || '',
      subLabel: getSubLabel(
        +(
          (isFirstAppointmentCompleted
            ? currentPP?.initialAppointmentCost
            : currentPP?.subsequentAppointmentCost) || 0
        )
      ),
      description: 'Video-based visit with a provider',
      value: false
    }
  ];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" icon="check" text={category} />
        <h1 className="text-m2xl font-bold text-primary-700">
          How would you like to be seen for {category.toLowerCase()}?
        </h1>
        {!isWeightManagement && (
          <p>You’ve got exclusive discounts with your Weight Management membership.</p>
        )}
      </div>
      <RadioGroup items={OPTIONS} onSelect={onSelect} />
    </div>
  );
};

export default QualifiedForAsync;
