import { useGetInsurancesQuery } from 'services/insurance/insurance';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import { VerificationBannerKeysProps } from 'widgets/VerificationBanner/verificationBanner.types';

import { useAppSelector } from './index';
import usePartnerPatient from './usePartnerPatient';
import useWeightManagement from './useWeightManagement';

export const useCompleteProfile = () => {
  const {
    data,
    refetch: refetchInsurance,
    isUninitialized,
    isFetching,
    isLoading
  } = useGetInsurancesQuery();

  const isBlueLinePatient = usePartnerPatient('Blue Line');
  const isBalladHealthPatient = usePartnerPatient('Ballad Health');
  const { isAsyncPlan, isWeightManagement } = useWeightManagement();
  const [getMyAccount, { isFetching: isFetchingMyAccount, isLoading: isLoadingMyAccount }] =
    useLazyGetMyAccountQuery();
  const { medicalIntakeCompleted, address, bodyImage } = useAppSelector(selectUser);

  const hasInsurance = data?.data.hasInsurance;
  const insurance = hasInsurance && data?.data.insurances.length > 0;

  const bannerParams = {
    address,
    insurance:
      !hasInsurance ||
      insurance ||
      !isWeightManagement ||
      isAsyncPlan ||
      isBlueLinePatient ||
      isBalladHealthPatient,
    medicalIntakeCompleted,
    bodyImage: !bodyImage?.uploadRequired
  };

  const filteredKeys: VerificationBannerKeysProps = Object.entries(bannerParams)
    .filter(([, value]) => !value)
    .map(([key]) => key) as VerificationBannerKeysProps;

  const refresh = async () => {
    await getMyAccount().unwrap();
    if (!isUninitialized) {
      await refetchInsurance().unwrap();
    }
  };

  return {
    filteredKeys,
    isFetching: isFetching || isFetchingMyAccount,
    isLoading: isLoading || isLoadingMyAccount,
    refresh
  };
};
