import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { PathName } from 'utils/enums';

import { SuccessProps } from './success.types';
import { TimeLineSection } from './TimeLine';

const Success: React.FC<SuccessProps> = ({ isPostonboardCompleted }) => {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(PathName.Dashboard);
  };

  const navigateToVerifyIdentity = () => {
    navigate({ pathname: PathName.IntakeForm, search: 's=verify-identity' });
  };

  return (
    <div className="flex h-full grow flex-col justify-between">
      <div>
        <Common.Icon
          className="mx-auto size-[96px] rounded-full bg-secondary-100 p-8 text-secondary"
          name="check"
        />
        <h1 className="my-4 text-center text-m2xl font-bold">
          {isPostonboardCompleted ? 'You’re all set!' : 'Success'}
        </h1>
        {!isPostonboardCompleted && (
          <p className="text-center">
            <span className="font-bold">Next step:</span> We need to collect some basic medical
            information. This should only take a couple of minutes.
          </p>
        )}
        <div className="my-4 flex flex-col gap-4">
          {isPostonboardCompleted && (
            <TimeLineSection>
              <p className="text-mSm font-medium text-gray-500">Medical intake form</p>
              <p className="text-mSm font-medium text-gray-500">Complete</p>
            </TimeLineSection>
          )}
          {isPostonboardCompleted && (
            <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
              <h2 className="text-mBase font-bold text-primary-400">Care team review</h2>
              <p className="font-medium text-gray-700">
                Next, our care team will review your answers.
              </p>
            </div>
          )}
          {!isPostonboardCompleted && (
            <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
              <h2 className="text-mBase font-bold text-primary-400">Medical intake form</h2>
              <ul className="list-inside list-[circle]">
                <li>Verify identity</li>
                <li>Complete questionnaire</li>
              </ul>
              <Common.Button
                color="blue"
                size="md"
                fullWidthOnMobile
                onClick={navigateToVerifyIdentity}
              >
                Next: Verify identity
              </Common.Button>
            </div>
          )}
          {!isPostonboardCompleted && (
            <TimeLineSection>
              <p className="text-mSm font-medium text-gray-700">Care team review</p>
              <p className="text-mSm font-medium text-gray-500">
                Our care team will review your answers.
              </p>
            </TimeLineSection>
          )}
          <TimeLineSection>
            <p className="text-mSm font-medium text-gray-700">Follow-up via messaging</p>
            <p className="text-mSm font-medium text-gray-500">
              Your provider will follow-up with next steps via messaging. If a prescription is
              warranted, we’ll send it to your pharmacy.
            </p>
          </TimeLineSection>
        </div>
      </div>
      <Common.Button color="white-alt" size="lg" fullWidthOnMobile onClick={navigateToDashboard}>
        View dashboard
      </Common.Button>
    </div>
  );
};

export default Success;
