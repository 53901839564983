import { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Range } from 'react-range';
import { Tooltip } from 'react-tooltip';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { SliderProps } from './slider.types';

const Slider: React.FC<SliderProps> = ({
  min,
  max,
  onChange,
  value,
  inputSuffix,
  outOfRangeLimit = false,
  defaultPivot
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const difference = max - min;
  const dividersLength = Math.min(difference, 10);
  const pivot = defaultPivot ?? Math.floor(difference / 2) + min;

  const handleFocus = () => {
    ref.current?.select();
    setIsFocused(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // ignore all non digits keys but also allow backspace and delete. Also allow to copy and paste
    if (
      !/^\d+$/.test(e.key) &&
      ![
        // '.', uncomment if we're allowing decimal numbers
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Tab',
        'Enter'
      ].includes(e.key)
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && document.activeElement === ref.current) {
        ref.current?.blur();
        setIsFocused(false);
      }
    };
    document.addEventListener('keypress', listener);
    return () => {
      document.removeEventListener('keypress', listener);
    };
  }, []);

  return (
    <div className="flex w-full flex-col gap-8">
      <div
        className={classNames(
          'flex items-end justify-center gap-1 bg-white self-center rounded-lg py-2',
          { 'pr-4': !!inputSuffix },
          !value ? 'text-gray-300' : 'text-primary-400',
          isFocused ? 'border border-blue' : 'border-b border-b-gray-300 shadow-sm'
        )}
      >
        <input
          className={classNames(
            'border-none !bg-transparent p-0 text-[38px] font-bold text-inherit placeholder:text-inherit focus:ring-0',
            !!inputSuffix ? 'text-end' : 'text-center'
          )}
          max={max}
          min={min}
          placeholder={String(value ?? Math.floor(pivot))}
          ref={ref}
          type="number"
          value={value}
          onBlur={() => {
            value !== undefined &&
              onChange(
                outOfRangeLimit
                  ? Math.min(
                      Math.max(Number(value ?? pivot), min - outOfRangeLimit),
                      max + outOfRangeLimit
                    )
                  : Math.min(Math.max(Number(value ?? pivot), min), max)
              );
            setIsFocused(false);
          }}
          onChange={(e) => {
            if (e.target.value === '') {
              return onChange(null);
            }
            let newValue = parseInt(e.target.value.replace(/^0+/, ''));
            newValue = outOfRangeLimit
              ? isNaN(newValue)
                ? min
                : Math.min(
                    isNaN(newValue) ? min - outOfRangeLimit : newValue,
                    max + outOfRangeLimit
                  )
              : Math.min(isNaN(newValue) ? min : newValue, max);
            onChange(newValue);
          }}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />
        <span className={`text-mLg ${isFocused ? 'text-gray-400' : 'text-inherit'}`}>
          {inputSuffix}
        </span>
      </div>
      <div
        className={classNames('flex flex-col gap-2 pt-1 transition-all', {
          'pointer-events-none opacity-0': isFocused
        })}
      >
        <div className="flex items-center gap-8">
          <span className="font-bold">{min}</span>
          <Range
            max={max}
            min={min}
            renderMark={({ props, index }) => {
              if (index > dividersLength) return null;
              return (
                <div
                  {...props}
                  className="h-2 w-px bg-gray-300"
                  data-testid="slider_divider"
                  key={index}
                  style={{
                    ...props.style,
                    left: `${index * (100 / dividersLength)}%`
                  }}
                />
              );
            }}
            renderThumb={({ props }) => (
              <>
                <Tooltip
                  className="z-10 -mt-1.5 !rounded-xl !bg-black !p-3 !shadow-sm"
                  classNameArrow="w-4 h-4"
                  id="name"
                  isOpen={!value && value !== 0}
                  place="bottom"
                />
                <div
                  {...props}
                  className={classNames(
                    'flex h-[38px] w-[55px] items-center justify-center gap-1 rounded-xl border-b-2 border-b-primary bg-primary-400',
                    {
                      '!hidden': Number(value) > max || Number(value) < min
                    }
                  )}
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    <span>Slide to select</span>
                  )}
                  data-tooltip-id="name"
                >
                  <Common.Icon className="text-white" name="arrow-left" />
                  <Common.Icon className="text-white" name="arrow-right" />
                </div>
              </>
            )}
            renderTrack={({ props, children }) => (
              <div {...props} className="h-[10px] w-full">
                <div className="absolute top-1 h-[3px] w-full bg-gray-300"></div>
                {children}
              </div>
            )}
            step={1}
            values={[value ?? pivot]}
            draggableTrack
            onChange={(values) => onChange(values[0])}
          />
          <span className="font-bold">{max}</span>
        </div>
      </div>
    </div>
  );
};

export default Slider;
