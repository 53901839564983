import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { LINK_TO_FAQ } from 'constants/externalLinks';
import useAnalytics from 'hooks/useAnalytics';
import { RED_FLAGS } from 'utils/constants';

import { RedFlagsProps } from './redFlags.types';

import CalendarSvg from 'assets/icons/ambulance.svg?react';

const RedFlags: React.FC<RedFlagsProps> = ({ onSelect, onSkip, selectedFlow, loading }) => {
  const logEvent = useAnalytics();

  const handleClickCancel = () => {
    logEvent('appt_agreement_cancel_btn_click');
    onSkip?.();
  };

  const handleClickAgree = () => {
    logEvent('appt_agreement_continue_btn_click');
    onSelect();
  };

  const handleClickFAQ = () => {
    logEvent('appt_agreement_faq_link_click');
  };

  return (
    <div className="flex flex-col gap-6 rounded-2xl bg-white p-4 max-md:border md:gap-4 md:px-12 md:py-8 md:shadow">
      <CalendarSvg className="mx-auto text-primary-100 md:size-[170px]" />
      <h2 className="text-mLg font-bold text-primary-700 md:text-xl" data-testid="heading">
        LifeMD virtual appointments are not intended for medical emergencies or for certain health
        conditions.
      </h2>
      <p className="font-medium">
        If you or a loved one are experiencing any of the following medical issues or any other
        critical or life-threatening situation, call 911 or your local emergency service right away:
      </p>
      <ul
        className="ml-4 flex list-disc flex-col flex-wrap gap-x-4 gap-y-0.5 font-bold md:mx-4 md:ml-6 md:max-h-[250px] md:gap-y-1"
        data-testid="red_flags_list"
      >
        {RED_FLAGS.map((item) => (
          <li className="md:w-[240px]" key={nanoid()}>
            {item}
          </li>
        ))}
      </ul>
      <Common.Alert type="info" colorableBackground>
        LifeMD does not provide prescriptions or refills for controlled substances such as
        benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
        <a className="underline" href={LINK_TO_FAQ} target="_blank" onClick={handleClickFAQ}>
          FAQ
        </a>
        .
      </Common.Alert>
      <div
        className={classNames(
          'flex flex-col items-center justify-center gap-3 md:mx-auto md:mt-6',
          selectedFlow ? 'md:max-w-[190px]' : 'md:max-w-[388px]'
        )}
      >
        <Common.Button
          color="blue"
          data-testid="agree_or_and_continue_btn"
          dataTestId="agree_or_and_continue_btn"
          disabled={loading}
          fullWidthOnMobile
          onClick={handleClickAgree}
        >
          Agree and continue
        </Common.Button>
        <Common.Button
          color="white-alt"
          dataTestId="cancel_btn"
          fullWidthOnMobile
          onClick={handleClickCancel}
        >
          Cancel
        </Common.Button>
      </div>
    </div>
  );
};

export default RedFlags;
