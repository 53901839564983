import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import useAnalytics from 'hooks/useAnalytics';
import useFreemium from 'hooks/useFreemium';
import { ACCOUNT_SETTINGS_LINKS } from 'utils/constants';
import { PathName } from 'utils/enums';

const AccountSettings = () => {
  useTitle('LifeMD - Account Settings');
  const logEvent = useAnalytics();

  const isFreemium = useFreemium();

  const handleClickAccountSettingsTab = (path: string) => {
    switch (path) {
      case PathName.AccountInformation:
        return logEvent('acc_settings_acc_info_item_click');
      case PathName.BillingDetails:
        return logEvent('acc_settings_billing_item_click');
      case PathName.NotificationsSettings:
        return logEvent('acc_settings_notifications_item_click');
      case PathName.ChangePassword:
        return logEvent('acc_settings_change_password_item_click');
      case PathName.Insurance:
        return logEvent('acc_settings_insurance_item_click');
    }
  };

  return ACCOUNT_SETTINGS_LINKS.filter(
    (el) => !(el.path === PathName.BillingDetails && isFreemium)
  ).map((el) => (
    <NavLink
      className="flex w-full cursor-pointer items-center gap-2 py-4 text-left text-mBase font-semibold md:font-bold"
      key={el.path}
      to={el.path}
      onClick={() => handleClickAccountSettingsTab(el.path)}
    >
      <Common.Icon className="size-5 text-gray-700" name={el.icon} />
      <span className="flex-1">{el.name}</span>
      <Common.Icon className="size-5 text-gray" name="arrow-right" />
    </NavLink>
  ));
};
export default AccountSettings;
