import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  Reducer,
  UnknownAction
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { appointmentsApi } from 'services/appointments/appointments';
import { authApi } from 'services/auth/auth';
import { channelsApi } from 'services/channels/channels';
import { documentsApi } from 'services/documents/documents';
import { externalApi } from 'services/external/external';
import { generalApi } from 'services/general/general';
import { healthMetricsApi } from 'services/healthMetrics/healthMetrics';
import { healthProfileApi } from 'services/healthProfile/healthProfile';
import { insuranceApi } from 'services/insurance/insurance';
import { krogerApi } from 'services/kroger/kroger';
import { labTestsApi } from 'services/labTests/labTests';
import { lookupApi } from 'services/lookup/lookup';
import { mifsApi } from 'services/mifs/mifs';
import { myAccountApi } from 'services/myAccount/myAccount';
import { ordersApi } from 'services/orders/orders';
import { patientChartApi } from 'services/patientChart/patientChart';
import { patientsApi } from 'services/patients/patients';
import { pharmaciesApi } from 'services/pharmacies/pharmacies';
import { providersApi } from 'services/providers/providers';
import { reportsApi } from 'services/reports/reports';
import { reviewApi } from 'services/review/review';
import { settingsApi } from 'services/settings/settings';
import { shopApi } from 'services/shop/shop';
import { subscriptionsApi } from 'services/subscriptions/subscriptions';
import { wmApi } from 'services/wm/wm';

import { notifyError } from 'shared/Toast/Toast';

import { IS_LIVE } from 'utils/constants';

import { FeatureStatusType } from 'models/user.types';

import appointmentsReducer from './appointments/appointmentsSlice';
import documentsReducer from './documents/documentsSlice';
import freemiumReduces from './freemium/freemiumSlice';
import healthMetricsReducer from './healthMetrics/healthMetricsSlice';
import healthProfileReducer from './healthProfile/healthProfileSlice';
import labTestsReducer from './labTests/labTestsSlice';
import lookupReducer from './lookup/lookupSlice';
import mifReducer from './mif/mifSlice';
import orchestrateReducer from './orchestrate-signup/orchestrateSlice';
import ordersReducer from './orders/ordersSlice';
import patientChartReducer from './patientChart/patientChartSlice';
import providerReducer from './provider/providerSlice';
import reportsReducer from './reports/reportsSlice';
import settingsReducer from './settings/settingsSlice';
import shopReducer from './shop/shopSlice';
import signupReducer from './signup/signupSlice';
import userReducer from './user/userSlice';
import wmReducer from './wm/wmSlice';

interface Payload {
  data?: {
    message?: string;
  };
  status: number | string;
}

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const payload = action.payload as Payload;

    if (payload && typeof payload === 'object' && 'status' in payload) {
      if (payload.status === 500 && payload.data?.message !== 'Subscription does not exist') {
        notifyError(
          payload.data?.message ||
            'Something is wrong with the server, and we are already working on fixing it.'
        );
        // eslint-disable-next-line no-console
        console.error(payload.data?.message);
      } else if (payload.status === 'FETCH_ERROR') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (action?.meta?.baseQueryMeta?.request?.url.includes('lifemd')) {
          notifyError('Server is updating at the moment, we need a couple of minutes', false, {
            toastId: 'fetch_error'
          });
        }
        // eslint-disable-next-line no-console
        console.error('CORS ERROR');
      }
    }
  }

  return next(action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'signUp',
    'provider',
    'lookup',
    'appointments',
    'freemium',
    'mif',
    'orchestrate'
  ]
};

const middlewares = [
  authApi.middleware,
  channelsApi.middleware,
  myAccountApi.middleware,
  providersApi.middleware,
  reportsApi.middleware,
  reviewApi.middleware,
  ordersApi.middleware,
  mifsApi.middleware,
  documentsApi.middleware,
  labTestsApi.middleware,
  lookupApi.middleware,
  patientChartApi.middleware,
  patientsApi.middleware,
  settingsApi.middleware,
  appointmentsApi.middleware,
  insuranceApi.middleware,
  generalApi.middleware,
  externalApi.middleware,
  healthMetricsApi.middleware,
  healthProfileApi.middleware,
  pharmaciesApi.middleware,
  subscriptionsApi.middleware,
  shopApi.middleware,
  krogerApi.middleware,
  wmApi.middleware,
  rtkQueryErrorLogger
];

const appReducer = combineReducers({
  appointments: appointmentsReducer,
  documents: documentsReducer,
  freemium: freemiumReduces,
  healthMetrics: healthMetricsReducer,
  healthProfile: healthProfileReducer,
  labTests: labTestsReducer,
  lookup: lookupReducer,
  mif: mifReducer,
  orchestrate: orchestrateReducer,
  orders: ordersReducer,
  patientChart: patientChartReducer,
  provider: providerReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  signUp: signupReducer,
  user: userReducer,
  wm: wmReducer,
  shop: shopReducer,
  [authApi.reducerPath]: authApi.reducer,
  [channelsApi.reducerPath]: channelsApi.reducer,
  [myAccountApi.reducerPath]: myAccountApi.reducer,
  [providersApi.reducerPath]: providersApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [reviewApi.reducerPath]: reviewApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [mifsApi.reducerPath]: mifsApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [labTestsApi.reducerPath]: labTestsApi.reducer,
  [lookupApi.reducerPath]: lookupApi.reducer,
  [patientChartApi.reducerPath]: patientChartApi.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
  [insuranceApi.reducerPath]: insuranceApi.reducer,
  [healthMetricsApi.reducerPath]: healthMetricsApi.reducer,
  [healthProfileApi.reducerPath]: healthProfileApi.reducer,
  [pharmaciesApi.reducerPath]: pharmaciesApi.reducer,
  [shopApi.reducerPath]: shopApi.reducer,
  [krogerApi.reducerPath]: krogerApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [wmApi.reducerPath]: wmApi.reducer
});

const rootReducer: Reducer = (state: RootState, action: UnknownAction) => {
  if (action.type === 'user/clearUser') {
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: !IS_LIVE,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  reducer: persistedReducer
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;

export const selectPatientChart = (state: RootState) => state.patientChart;
export const selectReports = (state: RootState) => state.reports.reports;
export const selectOrders = (state: RootState) => state.orders;
export const selectStates = (state: RootState) => state.lookup.states;
export const selectUser = (state: RootState) => state.user;
export const selectProvider = (state: RootState) => state.provider;
export const selectAppointments = (state: RootState) => state.appointments;
export const selectNewAppointment = (state: RootState) => state.appointments.newAppointment ?? {};
export const selectNewAppointmentExtended = (state: RootState) =>
  state.appointments.newAppointmentExtended ?? {};
export const selectSignUp = (state: RootState) => state.signUp;
export const selectMifInfo = (state: RootState) => state.mif;
export const selectLookup = (state: RootState) => state.lookup;
export const selectDocuments = (state: RootState) => state.documents;
export const selectHealthProfile = (state: RootState) => state.healthProfile;
export const selectShop = (state: RootState) => state.shop;
export const selectFreemium = (state: RootState) => state.freemium;
export const selectOrchestrate = (state: RootState) => state.orchestrate;
export const selectSelectedPricePoint = (state: RootState) => state.signUp.selectedPricePoint;
export const selectLatestHealthMetrics = (state: RootState) =>
  state.healthMetrics.latestHealthMetrics;
export const selectWmPages = (state: RootState) => state.wm.pages;
export const selectWmSections = (state: RootState) => state.wm.sections;

export const selectNotificationPreferences = (state: RootState) =>
  state.settings.notificationPreferences;
export const selectDocumentsAndLinksStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.documentsAndLinks?.status === status;
export const selectHealthProfileStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.healthProfile?.status === status;
export const selectLabsAndImagingStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.labsAndImaging?.status === status;
export const selectMedicalRecordsStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.medicalRecords?.status === status;
export const selectMyProviderStatus = (state: RootState, status: FeatureStatusType = 'disabled') =>
  state.user.features?.myProvider?.status === status;
export const selectMessagingStatus = (state: RootState, status: FeatureStatusType = 'disabled') =>
  state.user.features?.messaging?.status === status;
export const selectMigrateToBundlePlanStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.migrateToBundlePlan?.status === status;
export const selectPrescriptionDiscountCardStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.prescriptionDiscountCard?.status === status;
export const selectPrescriptionsStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.prescriptions?.status === status;
export const selectScheduleAppointmentStatus = (
  state: RootState,
  status: FeatureStatusType = 'disabled'
) => state.user.features?.scheduleAppointment?.status === status;
export const selectStoreStatus = (state: RootState, status: FeatureStatusType = 'disabled') =>
  state.user.features?.store?.status === status;
export const selectReOnboardingWMStatus = (
  state: RootState,
  status: FeatureStatusType = 'enabled'
) => state.user.features?.reOnboardingWM?.status === status;
// export const selectChoosingDoctorStatus = (
//   state: RootState,
//   status: FeatureStatusType = 'disabled'
// ) => state.user.features?.choosingDoctor?.status === status;

export const selectGlobalSettings = (state: RootState) => state.settings.globalSettings;
