import { useTitle } from 'react-use';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useCreateAppointmentExtended } from 'hooks/useCreateAppointmentExtended';

import Content from './Content';

const CreateAppointmentExtended = () => {
  useTitle('LifeMD - Schedule new appointment');

  const {
    currentStep,
    // exitFlow,
    isFetching,
    loading,
    moveToStep,
    isBackAnimation,
    currentPlan,
    selectedPlan,
    handleSelectTime,
    handleUpgradePlan,
    appointmentTypes
  } = useCreateAppointmentExtended();

  return (
    <FadeWrapper className="flex h-full flex-col md:pt-8">
      <Loader isVisible={isFetching} />
      <Header hideStepper onClickPrev={() => moveToStep('prev')} />
      <Content
        appointmentTypes={appointmentTypes}
        currentPlan={currentPlan}
        isBackAnimation={isBackAnimation}
        loading={loading}
        moveToStep={moveToStep}
        selectedPlan={selectedPlan}
        step={currentStep}
        onSelectDateAndTime={handleSelectTime}
        onUpgradePlan={handleUpgradePlan}
      />
    </FadeWrapper>
  );
};

export default CreateAppointmentExtended;
