import { useEffect, useState } from 'react';

import { useLazyGetDocumentsQuery } from 'services/documents/documents';
import { useVerifyIdentityMutation } from 'services/general/general';

import { selectOrchestrate, selectSignUp } from 'store';

import { DocsInfo } from 'features/FileZone/fileZone.types';

import { useAppSelector } from 'hooks';
import { handleRequestCatch } from 'utils/helpers';

export const useGetValidationDocs = (): {
  docs: DocsInfo;
  handleVerifyIdentity: (id: string, selfPortrait?: string, cb?: (value?: unknown) => void) => void;
  isLoading: boolean;
  isUpdating: boolean;
  refetchDocuments: () => void;
} => {
  const [getDocuments, { isLoading }] = useLazyGetDocumentsQuery();
  const [verifyIdentity, { isLoading: verifyIsLoading }] = useVerifyIdentityMutation();

  const {
    user: { accessToken: authorizedAccessToken }
  } = useAppSelector(selectSignUp);
  const {
    user: { accessToken: orchestrateSessionAccessToken }
  } = useAppSelector(selectOrchestrate);
  const accessToken = authorizedAccessToken || orchestrateSessionAccessToken;

  const [docs, setDocs] = useState<DocsInfo>({
    government: {
      _id: undefined,
      fileName: '',
      filePath: '',
      fileStatus: 'initial',
      identity: null
    },
    personPhoto: {
      _id: undefined,
      fileName: '',
      filePath: '',
      fileStatus: 'initial',
      identity: null
    }
  });
  const onInit = () => {
    getDocuments({ accessToken, category: 'user-identity', pageNo: 0 })
      .unwrap()
      .then((res) => {
        if (!res) {
          return;
        }
        const { data } = res;
        const governmentFile = data.find((item) => item.subCategory === 'government-issued-id');
        const personPhotoFile = data.find((item) => item.subCategory === 'self-portrait');
        setDocs({
          government: governmentFile
            ? {
                _id: governmentFile._id,
                fileName: governmentFile.fileName,
                filePath: governmentFile.filePath,
                fileStatus:
                  governmentFile.identity === null
                    ? 'success'
                    : governmentFile.identity?.isVerified
                      ? 'success'
                      : 'error',
                identity: governmentFile.identity
              }
            : {
                _id: undefined,
                fileName: '',
                filePath: '',
                fileStatus: 'initial',
                identity: null
              },
          personPhoto: personPhotoFile
            ? {
                _id: personPhotoFile._id,
                fileName: personPhotoFile.fileName,
                filePath: personPhotoFile.filePath,
                fileStatus:
                  personPhotoFile.identity === null
                    ? 'success'
                    : personPhotoFile.identity?.isVerified
                      ? 'success'
                      : 'error',
                identity: personPhotoFile.identity
              }
            : {
                _id: undefined,
                fileName: '',
                filePath: '',
                fileStatus: 'initial',
                identity: null
              }
        });
      });
  };

  const handleVerifyIdentity = (
    id: string,
    selfPortrait?: string,
    cb?: (isSuccess?: boolean) => void
  ) => {
    verifyIdentity({ governmentIssuedId: id, selfPortrait, accessToken })
      .unwrap()
      .then((res) => {
        onInit();
        cb?.(!!res.data.isVerified);
      })
      .catch((e) => {
        if (!e.data?.data) {
          handleRequestCatch(e);
          setDocs((prev) => prev);
        } else {
          cb?.(false);
        }
      });
  };

  useEffect(onInit, []);

  return {
    docs,
    handleVerifyIdentity,
    isLoading: isLoading,
    isUpdating: verifyIsLoading,
    refetchDocuments: onInit
  };
};
