import { Common } from '@thecvlb/design-system';

import Collapse from 'shared/Collapse';

import { SubscriptionRequiredProps } from './subscriptionRequired.types';

const SubscriptionRequired: React.FC<SubscriptionRequiredProps> = ({
  category,
  callType,
  onSelect,
  planPricePoint
}) => {
  const ITEMS = [
    {
      title: 'How does the LifeMD+ membership work?',
      content:
        'A LifeMD+ membership unlocks access to a comprehensive suite of healthcare benefits designed to simplify and enhance your health journey. With 24/7 access to healthcare providers — along with same-day prescription refills, discounted lab testing, and exclusive pricing on wellness programs and tools — makes it easier than ever to prioritize your health.\\n\\nPlease note: While the LifeMD+ membership comes with a range of valuable benefits, additional charges may apply for consultations, prescriptions, and/or lab work based on your needs and insurance coverage.'
    },
    ...(callType === 'video'
      ? [
          {
            title: 'Can I get an appointment on weekends or holidays?',
            content:
              'Yes! LifeMD+ offers 24/7 access to licensed healthcare providers, including weekends and holidays. Appointments are available within an hour, ensuring you can receive care when you need it most.'
          },
          {
            title: 'What if I need a prescription renewal quickly?',
            content:
              'LifeMD+ offers fast solutions for prescriptions that have been previously filled by a LifeMD-affiliated provider. You can request a prescription renewal through a message-based, asyc consultation. This consultation costs $5 (cash). If a prescription is deemed appropriate, it will be sent directly to your local pharmacy the same day, if requested during LifeMD service hours (7am-9pm ET).'
          },
          {
            title: 'Are lab tests available through LifeMD+?',
            content:
              'Yes. LifeMD+ members benefit from discounted rates on annual and specialty lab testing through Labcorp or Quest Diagnostics. A range of at-home diagnostic options is also available for purchase through LifeMD, including cholesterol, hormone, and other common panels to help you track and manage your health markers. Your LifeMD care team can send a lab order directly to your LifeMD patient portal.'
          }
        ]
      : [])
  ];

  const pricePerPeriod = (price: number, period: number) => {
    return period === 1 ? `${price}/mo` : `${price}/every $period mo`;
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" icon="check" text={category} />
        <h1 className="text-m2xl font-bold text-primary-700">
          Subscribe for access to appointments and more
        </h1>
      </div>
      <div className="flex gap-4 rounded-2xl border-2 border-blue bg-white p-6">
        <div>
          <Common.Checkbox color="blue" checked />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex items-center justify-between">
            <p>{planPricePoint?.planName}</p>
            <Common.ColorTag color="primary" size="md" text="Required" />
          </div>
          <p className="mt-1 text-mSm text-primary-400">
            <span className="text-primary-200 line-through">
              {pricePerPeriod(
                +(planPricePoint?.subsequentAppointmentCost ?? 0),
                planPricePoint?.paymentInterval.qty ?? 1
              )}
            </span>{' '}
            Free for your first month
          </p>
          <p className="mt-2 text-mSm">Discounted appointments, annual labs and more.</p>
          <ul className="mt-4 flex flex-col gap-2">
            {[
              '24/7 urgent & primary care with low, up-front costs per consult',
              'Access to async consultations for common health conditions',
              'Affordable same-day prescription refills for existing medications',
              'Comprehensive lab testing available',
              'Metabolic health & wellness offerings',
              'Exclusive member-only rates on specialty programs and partner offerings'
            ].map((item) => (
              <li className="flex gap-2 text-mSm" key={item}>
                <Common.Icon className="flex-none text-primary-400" name="check" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-semibold text-primary-700">Frequently Asked Questions</h2>
        {ITEMS.map((item) => (
          <Collapse key={item.title} title={item.title}>
            {item.content}
          </Collapse>
        ))}
      </div>
      <Common.Button className="m-auto" color="blue" fullWidthOnMobile onClick={onSelect}>
        {callType === 'video' ? 'Continue' : 'Continue to payment'}
      </Common.Button>
    </div>
  );
};

export default SubscriptionRequired;
