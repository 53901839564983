import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';

import { PrescriptionForProps, PrescriptionForType } from './prescriptionFor.types';

const ITEMS: { description: string; label: string; value: PrescriptionForType }[] = [
  {
    label: 'An urgent issue',
    description: 'Quick prescriptions available for common conditions.',
    value: 'urgent-issue'
  },
  {
    label: 'Another type of prescription',
    description: 'Renewals for ongoing prescriptions such as birth control or insomnia,',
    value: 'another-type'
  }
];
const PrescriptionFor: React.FC<PrescriptionForProps> = ({ onChoosePrescriptionFor }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" text="Prescriptions" />
        <h1 className="text-m2xl font-bold text-primary-700">
          What do you need a prescription for?
        </h1>
      </div>
      <RadioGroup items={ITEMS} onSelect={onChoosePrescriptionFor} />
    </div>
  );
};

export default PrescriptionFor;
