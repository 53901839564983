import { ChangeEvent, useImperativeHandle, useRef } from 'react';
import { useDropArea, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import Image from 'modals/Image';

import { FileZoneProps } from './fileZone.types';

const FileZone: React.FC<FileZoneProps> = ({
  onSelect,
  type,
  status: { fileName, filePath, fileStatus, _id },
  onDelete,
  loading,
  accept = 'image/*',
  placeholder,
  defaultIcon = 'camera',
  ref
}) => {
  const isProcessing = fileStatus === 'processing';
  const isSuccess = fileStatus === 'success';
  const isError = fileStatus === 'error';
  const isInitial = fileStatus === 'initial';

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpenImage, toggleIsOpenImage] = useToggle(false);
  const [bond, { over }] = useDropArea({
    onFiles: (files) => (isInitial || isError) && onSelect(files, type)
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    onSelect(Array.from(e.target.files), type);
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  useImperativeHandle(
    ref,
    () =>
      ({
        click: () => inputRef.current?.click()
      }) as HTMLInputElement
  );

  return (
    <>
      <Image filePath={filePath} isOpen={isOpenImage} onClose={toggleIsOpenImage} />
      <label
        {...bond}
        className={classNames(
          'relative flex h-[140px] w-full items-center justify-center rounded-2xl  px-4 py-6 text-gray transition-all duration-300',
          {
            'pointer-events-none': !isInitial && !isError,
            border: !isSuccess,
            'pointer-events-none border-gray': isError,
            'target-animate-div cursor-pointer active:border-gray-600 active:text-gray-600 md:hover:border-gray-600 md:hover:text-gray-600':
              isInitial
          },

          isProcessing
            ? 'bg-gray-200 text-gray-700'
            : isError
              ? 'bg-gray text-white'
              : over
                ? 'border-2 border-solid border-primary bg-primary-400'
                : 'border-dashed'
        )}
        data-testid={`file_zone_${fileStatus}`}
        htmlFor={`file_zone_${type}`}
        style={{
          background:
            filePath && !isProcessing && !isError
              ? `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), center / cover no-repeat url(${filePath})`
              : ''
        }}
      >
        {!!_id && isSuccess && (
          <button
            className="pointer-events-auto absolute right-3 top-3 text-white"
            data-testid="delete-button"
            disabled={loading}
            type="button"
            onClick={() => onDelete(_id)}
          >
            <Common.Icon name="close" />
          </button>
        )}
        <input
          accept={accept}
          className="hidden"
          data-testid="file_zone"
          disabled={loading}
          id={`file_zone_${type}`}
          ref={inputRef}
          type="file"
          onChange={handleChangeInput}
        />
        <div
          className={classNames('max-w-full', {
            'text-white': over,
            hidden: isSuccess
          })}
        >
          <Common.Icon
            className={classNames('m-auto', {
              'animate-spin text-gray': isProcessing,
              'text-white': isError
            })}
            name={
              isProcessing ? 'loader' : isError ? 'reset' : over ? 'arrow-alt-down' : defaultIcon
            }
          />
          <span className="mx-auto block max-h-[50px] max-w-full truncate text-pretty break-all text-center text-sm first-letter:uppercase max-md:max-w-[100px]">
            {isProcessing
              ? 'uploading...'
              : isError
                ? fileName.length > 100
                  ? fileName.slice(0, 100) + '...'
                  : fileName
                : over
                  ? 'drop photo to upload'
                  : placeholder || type}
          </span>
          {isError && (
            <Common.Button
              className="pointer-events-auto mx-auto mt-2 text-white hover:text-white/80"
              color="white-alt"
              size="sm"
              type="button"
              onClick={() => inputRef.current?.click()}
            >
              Replace
            </Common.Button>
          )}
        </div>
      </label>
    </>
  );
};

export default FileZone;
