import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { useDeleteDocumentMutation } from 'services/documents/documents';

import FileZone from 'features/FileZone';
import { DocsInfo } from 'features/FileZone/fileZone.types';
import { notifyError } from 'shared/Toast/Toast';

import { useUploadDocument } from 'hooks/useUploadDocument';

import { DocumentItem } from 'models/document.types';

import { PersonPhotoProps } from './personPhoto.types';

const INSTRUCTIONS = [
  'Your face must be well-lit and not blurry.',
  'You must be the only person in the photo.',
  'Nothing may cover your face, including sunglasses.'
];

export const PersonPhoto: React.FC<PersonPhotoProps> = ({
  onFileStatusUpdate,
  onFileUpdate,
  initialValue,
  governmentID,
  verifyIdentity,
  accessToken
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [useAsAvatar, toggleUseAsAvatar] = useToggle(true);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<DocsInfo['personPhoto']>({
    _id: undefined,
    fileName: '',
    filePath: '',
    fileStatus: 'initial',
    identity: null
  });

  const [deleteDocument] = useDeleteDocumentMutation();
  const { isLoading, uploadFile } = useUploadDocument();

  const verifyIdentityWithPortrait = (files: File[]) => {
    const images = files?.filter((item) =>
      ['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(item.type)
    );
    setCurrentFile(images[0]);
    onFileStatusUpdate();
  };

  const handleUploadThen = (data: DocumentItem, files: File[]) => {
    if (!!verifyIdentity) {
      verifyIdentity?.(governmentID, data._id, () => verifyIdentityWithPortrait(files));
    } else {
      setFileData((prev) => ({
        ...prev,
        ...data,
        fileStatus: 'success'
      }));
      onFileUpdate?.(files[0]);
      onFileStatusUpdate();
    }
  };

  const handleUpload = async (files: File[]) => {
    if (fileData._id) {
      await deleteDocument({ documentId: fileData._id, accessToken }).unwrap();
    }
    setFileData((prev) => ({
      ...prev,
      fileStatus: 'processing'
    }));
    const { data, error } = await uploadFile(files, {
      category: 'user-identity',
      subCategory: 'self-portrait'
    });
    if (error !== null) {
      setFileData((prev) => ({
        ...prev,
        fileStatus: 'initial'
      }));
      notifyError(error);
      onFileStatusUpdate();
    } else {
      handleUploadThen(data, files);
    }
  };

  const handleDelete = (id: string) => {
    deleteDocument({ documentId: id, accessToken })
      .unwrap()
      .then(() => {
        onFileUpdate?.(null);
        onFileStatusUpdate();
      });
  };

  const handleTryAnotherPhoto = () => {
    ref.current?.click();
  };

  const handleToggle = () => {
    Cookies.set('use-doc-as-avatar', !useAsAvatar ? '1' : '0');
    toggleUseAsAvatar();
  };

  useEffect(() => {
    onFileUpdate?.(useAsAvatar ? currentFile : null);
  }, [currentFile, useAsAvatar]);

  useEffect(() => {
    setFileData({
      ...initialValue,
      ...(!!accessToken && { fileStatus: !!initialValue._id ? 'success' : 'initial' })
    });
  }, [initialValue]);

  useEffect(() => {
    const isAgreedToUseAsAvatar = Cookies.get('use-doc-as-avatar');
    toggleUseAsAvatar(isAgreedToUseAsAvatar !== '0');
  }, []);

  const isSuccessful = fileData.fileStatus === 'success';

  return (
    <>
      <div className="flex flex-col items-center gap-4 text-center max-md:mt-6">
        <Common.Icon
          className="size-20 rounded-full text-primary-400 max-md:bg-primary-50 max-md:p-4 md:size-12"
          name="person-capture-photo"
        />
        <h2 className="verify-profile-heading">Upload a photo of yourself</h2>
        <div className="flex flex-col gap-1">
          {INSTRUCTIONS.map((text, index) => (
            <div
              className={classNames('flex items-center gap-1', { 'text-gray-600': !isSuccessful })}
              key={index}
            >
              <Common.Icon
                className={classNames('size-4 flex-none', { 'text-green': isSuccessful })}
                name={isSuccessful ? 'check' : 'arrow-alt-right'}
              />
              <p className="text-mSm">{text}</p>
            </div>
          ))}
        </div>
      </div>
      <Common.Checkbox checked={useAsAvatar} color="blue" size="sm" onChange={handleToggle}>
        Optional: Also use this for my profile photo
      </Common.Checkbox>
      <div className="w-full max-w-[267px] md:my-4" data-testid="person_photo_id">
        <FileZone
          accept="image/png, image/jpeg, image/jpg, .heic"
          loading={isLoading}
          ref={ref}
          status={fileData}
          type="Add photo"
          onDelete={handleDelete}
          onSelect={handleUpload}
        />
      </div>
      {fileData._id && !fileData.identity && (
        <Common.Alert className="w-full" type="warning" colorableBackground>
          <p>
            <b>File successfully uploaded,</b> but it’s taking some time to process.
          </p>
          <Common.Button
            className="mt-2"
            color="white-alt"
            size="md"
            onClick={handleTryAnotherPhoto}
          >
            Try another photo
          </Common.Button>
        </Common.Alert>
      )}
      {fileData._id && fileData?.identity?.errors.length && (
        <Common.Alert className="w-full" type="error" colorableBackground>
          <b>We had trouble verifying your identity.</b> Please fix the errors on the following
          pages in order to complete your onboarding.
        </Common.Alert>
      )}
    </>
  );
};
