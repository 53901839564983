import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps, WmFlowType } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { useAppSelector, useQuery } from 'hooks';
import { useGetSelectedItem } from 'hooks/useGetSelectedItem';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { PathName } from 'utils/enums';
import { buildSearch } from 'utils/helpers';

import Heading from '../../components/Heading';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';

const GallBladders: React.FC<ReducedSignupStepProps & { flow?: WmFlowType }> = ({
  moveToStep,
  flow = 'onboarding'
}) => {
  const navigate = useNavigate();
  const { value, setValue } = useGetSelectedItem('was_gallbladder_removed_withing_3_months');

  const query = useQuery();

  const { send, isLoading } = useSubmitOrchestrateForm();
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);

  const handleSelect = (v: boolean, question: 'primary' | 'secondary') => {
    if (question === 'secondary') {
      setValue(v);
      if (v === true) {
        navigate({
          search: buildSearch(
            flow === 'authorized' ? 's=wm-gallbladder-question' : '',
            `hideProgress=true&subStep=fail-gallbladder-question&answer=${v}`
          )
        });
      } else {
        setValue(false);
        send(
          'mif_qa',
          [{ answer: v, question: 'was_gallbladder_removed_withing_3_months' }],
          () => {
            setValue(undefined);
            moveToStep('next', { searchParams: '?shouldEmulateBack=true' });
          }
        );
      }
    }
    //  else {
    //   setActiveItem(Boolean(v));
    //   send('mif_qa', [{ answer: v, question: 'gallbladder_issues' }], () => {
    //     if (v === true) {
    //       setActiveItem(undefined);
    //       navigate({
    //         search: buildSearch(
    //           'subStep=have-you-had-your-gallbladder-removed&shouldEmulateBack=true'
    //         )
    //       });
    //     } else {
    //       moveToStep('next');
    //     }
    //   });
    // }
  };

  const handleProspect = () => {
    if (flow === 'authorized') {
      return navigate(PathName.Dashboard);
    }
    const answer = query.get('answer');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer, question: 'is_gallbladder_removed' }],
        () => {
          moveToStep(-1);
        },
        'PROSPECT'
      );
    } else {
      moveToStep(-1);
    }
  };

  const isErrorState = query.get('subStep') === 'fail-gallbladder-question';
  // const isSecondaryStep = query.get('subStep') === 'have-you-had-your-gallbladder-removed';

  return (
    <div className="flex flex-col gap-6">
      {
        isErrorState ? (
          <>
            <h2 className="wm-signup-title text-center" data-testid="header">
              We regret to inform you that you are not suitable for GLP-1 medication.
            </h2>
            <Common.Alert type="warning" colorableBackground>
              Given that your gallbladder has been removed, you are not suitable for treatment with
              GLP-1 receptor agonists. Your safety is our number one priority and these medications
              can sometimes cause or exacerbate gastrointestinal symptoms, which might be more
              pronounced or difficult to manage without a gallbladder.
            </Common.Alert>
            <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
          </>
        ) : (
          <>
            <Heading
              category="About you"
              title="Have you had your gallbladder removed within the last 3 months?"
            />
            <RadioGroup
              isLoading={isLoading}
              items={YES_NO_OPTIONS}
              selectedItem={value as boolean | undefined}
              onSelect={(v) => handleSelect(v, 'secondary')}
            />
          </>
        )
        //  : (
        //   <>
        //     <Heading category="Plan" title="Do you have any gallbladder issues?" />
        //     <RadioGroup
        //       isLoading={isLoading}
        //       items={YES_NO_OPTIONS}
        //       selectedItem={activeItem}
        //       onSelect={(v) => handleSelect(v, 'primary')}
        //     />
        //   </>
        // )
      }
    </div>
  );
};

export default GallBladders;
