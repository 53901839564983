import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import HTMLReactParser from 'html-react-parser';

import { useGetSignableDocumentsQuery } from 'services/general/general';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import { PathName } from 'utils/enums';

const MedicaidWaiver = () => {
  useTitle('LifeMD - Medicaid Waiver');

  const { data, isFetching } = useGetSignableDocumentsQuery();

  const { html } = data?.data.find((item) => item.type === 'medicaid-waiver-terms') || {};

  return (
    <FadeWrapper className="flex flex-col gap-4 md:p-8">
      <Loader isVisible={isFetching} />
      <NavLink className="flex items-center gap-3 max-md:hidden" to={PathName.Legal}>
        <Common.Icon name="arrow-left" />
        <h1 className="text-xl font-bold">Medicaid waiver</h1>
      </NavLink>
      <div className="mx-auto flex w-full max-w-[500px] flex-col gap-8">
        <div className="text-primary-700" data-testid="agreement_block">
          {HTMLReactParser(html || '')}
        </div>
        <div className="flex flex-col gap-2 py-6">
          <p className="text-mXs font-semibold text-primary-700">Agreed on 11/12/2024 by</p>
          <p className="text-gray">Evan Bates</p>
        </div>
      </div>
    </FadeWrapper>
  );
};
export default MedicaidWaiver;
