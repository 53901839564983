import { ReactNode } from 'react';

export const Timeline = () => {
  return (
    <div className="relative w-0 shrink-0 border-l border-l-[#E1E1E1]">
      <div className="absolute right-[-2px] top-[10px] size-[5px] rounded-full bg-[#959595]"></div>
    </div>
  );
};

export const TimeLineSection = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex gap-4">
      <Timeline />
      <div className="flex flex-col">{children}</div>
    </div>
  );
};
