import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { LINK_TO_ACCESSIBILITY } from 'constants/externalLinks';
import useAnalytics from 'hooks/useAnalytics';
import { ACCOUNT_ABOUT_LINKS } from 'utils/constants';

import packageInfo from '../../../../../package.json';

const About = () => {
  useTitle('LifeMD - About');

  const logEvent = useAnalytics();

  return (
    <FadeWrapper className="flex flex-col gap-4 md:p-8">
      <h1 className="text-xl font-bold max-md:hidden">About</h1>
      {ACCOUNT_ABOUT_LINKS.map((el) => (
        <NavLink
          className="flex w-full cursor-pointer items-center gap-2 py-4 text-left text-mBase font-semibold md:px-4 md:font-bold"
          data-acsb-custom-trigger="true"
          key={el.name}
          target={el.path === LINK_TO_ACCESSIBILITY ? '_blank' : undefined}
          to={el.path}
          onClick={() => logEvent(`my_account_${el.name}_mitem_click`)}
        >
          <Common.Icon className="size-5 text-gray-700" name={el.icon} />
          <span className="flex-1">{el.name}</span>
          <Common.Icon className="size-5 text-gray" name="arrow-right" />
        </NavLink>
      ))}
      <span className="mx-auto py-4 text-gray md:hidden">v{packageInfo.version}</span>
    </FadeWrapper>
  );
};
export default About;
