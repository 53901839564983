import { useLogoutMutation } from 'services/auth/auth';

import { selectUser } from 'store';
import { clearUser } from 'store/user/userSlice';

import { useAppDispatch, useAppSelector, useMessages } from './index';

export const useLogout = () => {
  const [logout] = useLogoutMutation();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { clearChannels } = useMessages();

  const handleClearStore = () => {
    localStorage.setItem('logout-event', 'logout' + Math.random());
    dispatch(clearUser());
    clearChannels();
    sessionStorage.clear();
  };

  return () => {
    logout(user.accessToken).unwrap().finally(handleClearStore);
  };
};
