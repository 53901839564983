import { Tooltip } from 'react-tooltip';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { getStatusData } from 'pages/WeightManagement/components/PrescriptionStatus/prescriptionStatus.settings';

import PrescriptionProgress from 'features/ShippingProgress';

import { PRESCRIPTION_INSTRUCTIONS_URL, PRESCRIPTION_TRACKING_URL } from 'constants/externalLinks';
import useAnalytics from 'hooks/useAnalytics';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { DateFormat, FeatureFlag, ShippingStatuses, TaskStatuses } from 'utils/enums';
import { checkIfPrescriptionIsGLP, isAdminPrescription } from 'utils/helpers';

import { handleDatePhrase } from './prescription.settings';
import { PrescriptionProps } from './prescription.types';

const Prescription: React.FC<PrescriptionProps> = ({
  prescription,
  createRenewalIsLoading,
  createSurveyIsLoading,
  onCreateSurvey,
  onCreateRenewal
}) => {
  const logEvent = useAnalytics();
  const { isWeightManagement } = useWeightManagement();
  const { isMobile } = useWidth();
  const isEnabledGLP = useFlag(FeatureFlag.GLPPrescription);
  const format = isMobile ? DateFormat.MMM_DD : DateFormat.MMM_DD_YYYY;

  const title = isAdminPrescription(prescription)
    ? prescription.drug + ` (${prescription.drugDosage})`
    : prescription.name;

  const isGLPKit = !!(
    !isAdminPrescription(prescription) &&
    prescription.supplements?.find((el) => el.quantityUnits === 'kit')
  );

  const titleClassName = classNames(
    'text-gray-700',
    checkIfPrescriptionIsGLP(prescription) && isEnabledGLP ? 'max-w-[250px]' : 'max-w-[300px]'
  );

  const handleClickInstructions = () => {
    logEvent('prescriptions_instructions_btn_click');
    window.open(PRESCRIPTION_INSTRUCTIONS_URL, '_blank');
  };

  const handleClickViewTracking = () => {
    logEvent('prescriptions_view_tracking_btn_click');
    if (!isAdminPrescription(prescription)) {
      window.open(PRESCRIPTION_TRACKING_URL + prescription.trackingInfo?.trackingNumber, '_blank');
    }
  };

  const getTime = () => {
    if (isAdminPrescription(prescription)) return '';
    const hours = dayjs(prescription.renewalRequestAllowedAt).diff(dayjs(), 'hours');
    const days = Math.floor(hours / 24);
    return days > 0 ? `${days} day${days > 1 ? 's' : ''}` : `${hours} hour${hours > 1 ? 's' : ''}`;
  };

  return (
    <div className="flex justify-between gap-3 py-4 max-md:flex-col md:items-center">
      <div className="flex gap-3">
        <div className="flex h-[64px] w-[80px] items-center justify-center rounded-2xl bg-primary-50 px-4 text-mBase font-bold text-primary">
          {isGLPKit ? (
            'GLP-1'
          ) : isAdminPrescription(prescription) ? (
            <Common.Logo name={prescription.brand} />
          ) : (
            <Common.Icon name="prescription" />
          )}
        </div>
        <div className="flex flex-1 flex-col gap-0.5 md:flex-row md:items-center md:justify-between md:gap-2">
          <div>
            <p className={titleClassName} title={title}>
              {title} {isGLPKit && 'GLP-1 Kit'}
            </p>
            {!isAdminPrescription(prescription) && prescription.directions && (
              <p className={titleClassName}>{`(${prescription.directions})`}</p>
            )}
            {!isAdminPrescription(prescription) && (
              <p className="text-mSm text-gray md:mt-1 md:text-sm">
                {handleDatePhrase(prescription, format)}
              </p>
            )}
            {!isAdminPrescription(prescription) && prescription?.auth_refills > 0 && (
              <p className="text-mSm text-gray md:mt-1 md:text-sm">
                {`${prescription.auth_refills} ${
                  prescription.auth_refills > 1 ? 'refills' : 'refill'
                } available`}
              </p>
            )}
          </div>
        </div>
      </div>
      {!isAdminPrescription(prescription) &&
        isEnabledGLP &&
        checkIfPrescriptionIsGLP(prescription) && (
          <div className="flex w-full grow flex-col p-4 max-md:mx-auto max-md:mb-7 max-md:mt-3 md:max-w-[340px] md:items-center">
            <PrescriptionProgress phases={getStatusData(prescription.trackingInfo)} />
          </div>
        )}
      <div className="flex gap-2">
        <div className="flex w-full flex-none flex-col items-end justify-center gap-2 self-stretch md:w-[160px]">
          {isEnabledGLP &&
            !isAdminPrescription(prescription) &&
            prescription.trackingInfo?.shippingStatus === ShippingStatuses.Delivered &&
            !prescription.survey?.checkInReceived &&
            !prescription.isRenewalCreated &&
            prescription.surveyVersion !== 'v2' &&
            isWeightManagement && (
              <Common.Button
                color="blue"
                dataTestId="mark_as_received_btn"
                disabled={createSurveyIsLoading}
                size={isMobile ? 'md' : 'sm'}
                fullWidthOnMobile
                onClick={onCreateSurvey}
              >
                Mark as received
              </Common.Button>
            )}
          {!isAdminPrescription(prescription) && prescription.trackingInfo?.trackingNumber && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              dataTestId="view_tracking_btn"
              disabled={createSurveyIsLoading}
              size={isMobile ? 'md' : 'sm'}
              fullWidthOnMobile
              onClick={handleClickViewTracking}
            >
              View tracking
            </Common.Button>
          )}
          {!isAdminPrescription(prescription) && prescription.isCompound && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              dataTestId="instructions_btn"
              size={isMobile ? 'md' : 'sm'}
              fullWidthOnMobile
              onClick={handleClickInstructions}
            >
              Instructions
            </Common.Button>
          )}
          {isAdminPrescription(prescription) && prescription.orderUrl && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              size={isMobile ? 'md' : 'sm'}
              onClick={() => window.open(prescription.orderUrl)}
            >
              Manage prescription
            </Common.Button>
          )}
          {!isAdminPrescription(prescription) &&
            prescription.isRenewalCreated &&
            prescription.renewalTask?.status !== TaskStatuses.COMPLETED &&
            !prescription.renewalSurvey?.canceledAt &&
            prescription.renewalSurvey?.completedAt && (
              <Common.Button
                className="cursor-default text-green"
                preIcon="check"
                size={isMobile ? 'md' : 'sm'}
                fullWidthOnMobile
              >
                Renewal requested
              </Common.Button>
            )}
          {!isAdminPrescription(prescription) &&
            prescription.isRenewalRequestAllowed &&
            prescription.renewalRequestAllowedAt && (
              <>
                {dayjs(prescription.renewalRequestAllowedAt).diff(dayjs(), 'hours') < 1 ? (
                  <Common.Button
                    color={isMobile ? 'white' : 'white-alt'}
                    dataTestId="request_renewal_btn"
                    disabled={createRenewalIsLoading}
                    isLoading={createRenewalIsLoading}
                    size={isMobile ? 'md' : 'sm'}
                    fullWidthOnMobile
                    onClick={onCreateRenewal}
                  >
                    Request renewal
                  </Common.Button>
                ) : (
                  <>
                    <Tooltip
                      className="max-w-[200px] !rounded-xl !bg-black !p-3 !text-white"
                      classNameArrow="w-4 h-4"
                      id="name"
                    />
                    <Common.Button
                      data-tooltip-html={`Your current ${prescription.renewalFrequency?.supply} day supply is not ready to be renewed. You will be able to request a renewal on ${dayjs(prescription.renewalRequestAllowedAt).format(DateFormat.MMM_DD)}.`}
                      data-tooltip-id="name"
                      preIcon="calendar"
                      size={isMobile ? 'md' : 'sm'}
                      fullWidthOnMobile
                    >
                      Available to renew in {getTime()}
                    </Common.Button>
                  </>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default Prescription;
