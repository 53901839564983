import { useState } from 'react';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { useDeleteDocumentMutation } from 'services/documents/documents';

import UpdateBirthday from 'modals/UpdateBirthday';
import VerifyYourIdentity from 'modals/VerifyYourIdentity';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import useAnalytics from 'hooks/useAnalytics';
import { useGetValidationDocs } from 'hooks/useGetValidationDocs';

import { Props } from './intro.types';

const Intro: React.FC<Props> = ({ onContinue, onSkip, inPopup = false, loading = false }) => {
  useTitle('Verify your identity', { restoreOnUnmount: true });

  const logEvent = useAnalytics();

  const {
    docs: { government, personPhoto },
    isLoading: isGettingDocuments,
    isUpdating: isUpdatingDocuments,
    refetchDocuments,
    handleVerifyIdentity
  } = useGetValidationDocs();

  const [deleteDocument, { isLoading: isDeletingDocument }] = useDeleteDocumentMutation();

  const [isOpenUpdateBirthday, toggleIsOpenUpdateBirthday] = useToggle(false);
  const [verifyYourIdentityType, setVerifyYourIdentityType] = useState<'SELFIE' | 'GV'>();

  const filesUploaded = government._id && personPhoto._id;
  const filesIdentified = government.identity && personPhoto.identity;
  const filesVerified = government.identity?.isVerified && personPhoto.identity?.isVerified;

  const mergedErrors = [
    ...(government.identity?.errors || []),
    ...(personPhoto.identity?.errors || [])
  ].reduce(
    (acc, current) => {
      const isDuplicate = acc.some((error) => error.message === current.message);
      return isDuplicate ? acc : [...acc, current];
    },
    [] as { message: string; type: string }[]
  );

  const handleDelete = (documentId: string) => {
    deleteDocument({ documentId }).unwrap().then(refetchDocuments);
  };

  const handleSubmit = () => {
    logEvent('verify_identity_continue_btn_click');

    if (!government._id || (government.identity && !government.identity.isVerified))
      return setVerifyYourIdentityType('GV');
    if (!personPhoto._id || (personPhoto.identity && !personPhoto.identity.isVerified))
      return setVerifyYourIdentityType('SELFIE');

    handleVerifyIdentity(government._id, personPhoto._id, (isVerified) => {
      if (isVerified) {
        onContinue();
      } else {
        refetchDocuments();
      }
    });
  };

  const handleOnCloseUpdateBirthday = (triggerVouched?: boolean) => {
    if (triggerVouched && government._id) {
      handleVerifyIdentity(government._id);
    }
    toggleIsOpenUpdateBirthday();
  };

  const card = (type: 'SELFIE' | 'GV') => {
    const isSelfie = type === 'SELFIE';
    const { identity, _id, filePath } = type === 'GV' ? government : personPhoto;
    const status =
      filesUploaded && identity ? (identity?.isVerified ? 'success' : 'error') : undefined;
    const isDisabled = (isSelfie && !government._id) || isDeletingDocument;

    const handleClick = () => {
      logEvent(
        isSelfie
          ? 'verify_identity_selfie_photo_btn_click'
          : 'verify_identity_government_id_btn_click'
      );
      !isDisabled && setVerifyYourIdentityType(type);
    };

    return (
      <div
        className={classNames(
          'relative flex flex-1 flex-col items-center rounded-xl bg-white justify-center shadow min-h-[140px]',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        style={{
          background: filePath
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), center / cover no-repeat url(${filePath})`
            : ''
        }}
        onClick={handleClick}
      >
        {status && (
          <div className="absolute left-3 top-3">
            {status === 'error' ? (
              <div className="flex size-4 items-center justify-center rounded-full bg-red text-2xs text-white">
                !
              </div>
            ) : (
              <Common.Icon className="rounded-full bg-green p-1 text-white" name="check" />
            )}
          </div>
        )}
        {filePath && (
          <button
            className="absolute right-3 top-3"
            onClick={(e) => {
              e.stopPropagation();
              _id && handleDelete(_id);
            }}
          >
            <Common.Icon className="text-white" name="close" />
          </button>
        )}
        {!filePath && (
          <>
            <Common.Icon
              className="size-12 text-primary-400"
              name={type === 'GV' ? 'identification' : 'person-capture-photo'}
            />
            <p className="mb-4 font-semibold">{type === 'GV' ? 'Government ID' : 'Selfie photo'}</p>
            <Common.Button color="white" disabled={isDisabled} size="sm">
              Upload
            </Common.Button>
          </>
        )}
      </div>
    );
  };

  const alertText =
    'To comply with regulations, we need to confirm your identity. We will never share this information.';

  return (
    <FadeWrapper
      className={classNames('h-full md:rounded-2xl md:bg-white md:p-8', { 'md:shadow': !inPopup })}
    >
      <UpdateBirthday isOpen={isOpenUpdateBirthday} onClose={handleOnCloseUpdateBirthday} />
      <Loader isVisible={isGettingDocuments || isDeletingDocument} />
      <VerifyYourIdentity
        docs={{ government, personPhoto }}
        refetchDocuments={refetchDocuments}
        type={verifyYourIdentityType}
        onClose={() => setVerifyYourIdentityType(undefined)}
      />
      <div className="mx-auto flex h-full max-w-[500px] flex-col items-center md:gap-4">
        <Common.Icon
          className="size-20 rounded-full bg-primary-50 p-4 text-primary-400 max-md:hidden"
          name="tag-person"
        />
        <h2 className="text-m2xl font-bold text-primary-700 max-md:self-start md:text-2xl">
          Verify your identity
        </h2>
        <span className="mt-2 text-mSm text-gray-600 md:hidden">{alertText}</span>
        <Common.Alert className="text-gray-600 max-md:hidden" type="plain" colorableBackground>
          <span className="text-sm">{alertText}</span>
        </Common.Alert>
        <div className="flex w-full gap-3 max-md:my-6">
          {card('GV')}
          {card('SELFIE')}
        </div>
        {filesIdentified && !filesVerified && !mergedErrors.length && (
          <Common.Alert type="warning" colorableBackground>
            <span className="text-mSm md:text-sm">
              <b>We had trouble verifying your identity.</b> Our team can attempt to verify them
              manually, or you can try another photo.
            </span>
          </Common.Alert>
        )}
        {mergedErrors.map((error) => (
          <Common.Alert
            className="w-full max-md:mb-6"
            key={nanoid()}
            type="error"
            colorableBackground
          >
            <b>Error:</b> {error.message}
            {error.type === 'BirthDateMatchError' && (
              <Common.Button
                className="mt-2"
                color="white-alt"
                onClick={toggleIsOpenUpdateBirthday}
              >
                Update birthdate
              </Common.Button>
            )}
          </Common.Alert>
        ))}
        <div className="mt-auto flex w-full flex-col items-center gap-4 md:mt-4 md:gap-2">
          <Common.Button
            color="blue"
            disabled={loading || !filesUploaded}
            isLoading={isUpdatingDocuments}
            fullWidthOnMobile
            onClick={handleSubmit}
          >
            {filesIdentified ? 'Try another photo' : 'Submit'}
          </Common.Button>
          <Common.Button
            disabled={loading || isUpdatingDocuments}
            fullWidthOnMobile
            onClick={onSkip}
          >
            {filesIdentified ? 'Continue anyway' : 'I’ll do this later'}
          </Common.Button>
        </div>
      </div>
    </FadeWrapper>
  );
};

export default Intro;
