import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import Stepper from 'shared/ProgressBar/ProgressBar';

import { SUPPORT_PHONE_NUMBER } from 'utils/constants';
import { FlowTypes } from 'utils/enums';

import { HeaderProps } from './header.types';

import fop from 'assets/images/signUp/fop.webp';
import fopTwo from 'assets/images/signUp/fop-2.webp';

const Header: React.FC<HeaderProps> = ({
  hideStepper,
  flowLength,
  step,
  onClickPrev,
  isShowBackButton = true,
  flow,
  isShowCenterLogos = true,
  isKrogerPatient
}) => {
  const logoClassName = 'size-7 md:h-8 md:w-8';
  return (
    <div className="sticky top-0 z-10 w-screen max-w-full bg-gray-100">
      <div className="flex flex-col-reverse md:flex-col md:gap-6 md:pb-0">
        {!hideStepper && flowLength != undefined && step != undefined && (
          <div className="max-md:p-4">
            <Stepper color="primary" step={step + 1} totalSteps={flowLength} rounded />
          </div>
        )}
        <header className="flex items-center justify-between px-4 max-md:py-6 md:px-6">
          <button
            className={classNames('flex items-center p-2.5', {
              invisible: !isShowBackButton
            })}
            data-testid="arrow_back"
            onClick={onClickPrev}
          >
            <Common.Icon className="size-5 text-gray-700 md:text-gray" name="arrow-left" />
          </button>

          {isShowCenterLogos && (
            <div className="flex items-center gap-4">
              {isKrogerPatient ? (
                <Common.Logo name="lifemd-kroger" />
              ) : (
                <>
                  <Common.Logo className={logoClassName} name="cross" />
                  {flow === FlowTypes.WeightManagementBalladHealth && (
                    <>
                      <Common.Icon name="close" />
                      <Common.Logo className={logoClassName} name="ballad-health" />
                    </>
                  )}
                  {flow == FlowTypes.BlueLineFlow && (
                    <>
                      <Common.Icon name="close" />
                      <div className="flex items-center gap-2">
                        <img className={logoClassName} src={fop} />
                        <img className={logoClassName} src={fopTwo} />
                      </div>
                    </>
                  )}
                  {flow === FlowTypes.WeightManagementFlowOptavia && (
                    <>
                      <Common.Icon name="close" />
                      <Common.Logo className={logoClassName} name="optavia" />
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <a className="p-2" href={SUPPORT_PHONE_NUMBER.link}>
            <Common.Icon className="size-7 text-primary-700 md:size-6" name="phone" />
          </a>
        </header>
      </div>
    </div>
  );
};

export default Header;
