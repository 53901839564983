import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { useGetSet } from 'react-use';

import {
  useAddAppointmentMutation
  // useUploadFilesMutation
} from 'services/appointments/appointments';
import { useGetAppointmentTypesQuery, useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectNewAppointmentExtended, selectUser } from 'store';
import { AppointmentsState } from 'store/appointments/appointments.types';
import {
  clearNewAppointmentExtended,
  setNewAppointmentExtended
} from 'store/appointments/appointmentsSlice';
import { clearAppointmentMif } from 'store/mif/mifSlice';
import { setUser } from 'store/user/userSlice';

import {
  PossibleMoveToStepTypes,
  Props
} from 'containers/CreateAppointmentExtended/Content/content.types';
import {
  buildBodyForApptSchedule,
  defineFlowSteps
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.settings';
import {
  CareTypes,
  CREATE_APPOINTMENT_STEPS,
  FillAppointmentDataProps,
  StepName
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';
import { notifyError } from 'shared/Toast/Toast';

import { WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS } from 'constants/pricepoints';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import usePartnerPatient from './usePartnerPatient';

const buildPayload = (
  type: PossibleMoveToStepTypes
): Partial<AppointmentsState['newAppointmentExtended']> | null => {
  let payload: Partial<AppointmentsState['newAppointmentExtended']> | null = null;
  if (typeof type === 'object') {
    switch (type.step) {
      case 'appointment-types-picker':
        const { status, ...rest } = type.data;
        payload = rest;
        break;
      case 'choose-type-of-care':
        payload = { src: type.data };
        break;
      case 'details-for-provider':
        payload = { appointmentDescription: type.data.appointmentDescription, callType: 'video' };
        break;
      // add more conditions here
      default:
        break;
    }
  }
  return payload;
};

const getNextStep = (type: FillAppointmentDataProps, src: string): StepName | 'shop' | null => {
  let nextStep: StepName | 'shop' | null = null;
  switch (type.step) {
    case 'choose-type-of-care':
      nextStep = src === 'shop-labs' ? 'shop' : 'red-flags';
      break;
    case 'red-flags':
      switch (src) {
        case 'prescriptions':
          nextStep = 'prescription-for';
          break;
        case 'talk-to-a-doctor':
          nextStep = 'appointment-types-picker';
          break;
        default:
          break;
      }
      break;
    case 'appointment-types-picker':
      nextStep = type.data.asyncAllowed ? 'mif' : 'details-for-provider';
      break;
    case 'prescription-for':
      switch (type.data.prescriptionForValue) {
        case 'urgent-issue':
          nextStep = 'appointment-types-picker';
          break;
        case 'another-type':
          nextStep = 'prescriptions-type';
          break;
        default:
          break;
      }
      break;
    case 'is-renew':
      nextStep = 'details-for-provider';
      break;
    case 'details-for-provider':
      nextStep = type.data.hasAccount ? 'subscription-required' : 'create-account-intro';
      break;
    case 'prescriptions-type':
      switch (type.data.prescriptionsTypeValue) {
        case 'weight-management':
          nextStep = 'is-renew';
          break;
        case 'something-else':
          nextStep = 'details-for-provider';
          break;
        default:
          break;
      }
      break;
    case 'subscription-required':
      nextStep = type.data.callType === 'video' ? 'date-time' : 'payment-checkout';
      break;
    default:
      return null;
  }
  return nextStep;
};

export const useCreateAppointmentExtended = () => {
  const [getMyAccount, { isFetching }] = useLazyGetMyAccountQuery();

  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const { activePlanCode, isUnlimitedPlan, activePricePoint, accessToken } =
    useAppSelector(selectUser);
  const { membershipData, src, ...rest } = useAppSelector(selectNewAppointmentExtended);

  const isOptaviaPatient = usePartnerPatient('Optavia');
  const isHRTPatient = usePartnerPatient('HRT');

  const partner = isHRTPatient ? 'HRT' : isOptaviaPatient ? 'Optavia' : null;
  const {
    data: appointmentTypes,
    isLoading: isLoadingApptTypes,
    isFetching: isFetchingApptTypes
  } = useGetAppointmentTypesQuery({
    ...(!!partner && { partner }),
    limit: 50,
    accessToken: ''
  });

  const [updateMyAccount, { isLoading: isLoadingUpdateUser }] = useUpdateMyAccountMutation();
  const [addAppointment, { isLoading: isLoadingAddAppointment }] = useAddAppointmentMutation();
  const { data: plans, isFetching: isFetchingPlans } = useGetMembershipPlansQuery();
  // const [uploadFiles] = useUploadFilesMutation();

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);
  const [steps, setSteps] = useGetSet([...CREATE_APPOINTMENT_STEPS]);

  const predefinedCategory = query.get('c');
  const currentStep = (query.get('s') ?? '') as StepName;
  const srcFromQuery = (useQuery().get('src') as CareTypes) ?? '';
  const dateFromQuery = query.get('date') || '';

  const currentPlan = plans?.data.find((p) => p.planCode === activePlanCode);
  const selectedPlan = plans?.data.find((p) => p._id === membershipData?.planId);

  const isInsurancePatient =
    !!activePricePoint && WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS.includes(activePricePoint);

  const exitFlow = () => {
    navigate(PathName.Dashboard);
    dispatch(clearNewAppointmentExtended());
  };

  const scheduleAppointment = () => {
    const body = buildBodyForApptSchedule({
      ...rest,
      callType: 'video',
      isWeightManagementAppointment: false
    });
    const scheduleAppointmentThen = () => {
      sessionStorage.setItem('appointmentIsScheduled', 'true');
      dispatch(clearAppointmentMif());
      moveToStep('confirmation');
      dispatch(setNewAppointmentExtended({ status: 'created' }));
    };

    addAppointment(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(setNewAppointmentExtended({ _id: data._id }));
        // if (rest.files?.length && rest.uploadRequired) {
        //   const formData = new FormData();
        //   rest.files.forEach((file: File) => {
        //     formData.append('appointmentImages', file);
        //   });
        //   uploadFiles({
        //     appointmentId: data._id,
        //     body: formData
        //   })
        //     .unwrap()
        //     .catch(() => {
        //       toast.warn(
        //         'Appointment is scheduled successfully but there is an error with the uploading of the files'
        //       );
        //     })
        //     .finally(scheduleAppointmentThen);
        // } else {
        scheduleAppointmentThen();
        // }
      })
      .catch((e) => {
        handleRequestCatch(e);
        moveToStep('date-time');
      });
  };

  const handleSelectTime = () => {
    if (!isInsurancePatient && isUnlimitedPlan) {
      scheduleAppointment();
    } else {
      moveToStep('next');
    }
  };

  const handleUpgradePlan = () => {
    const isChangingPlan =
      (!!membershipData.planId && membershipData.planId !== currentPlan?._id) ||
      (!!membershipData.planPricePoint &&
        membershipData.planPricePoint.planPricePointId !== activePricePoint);

    if (!isChangingPlan) {
      return scheduleAppointment();
    }
    updateMyAccount({
      planId: membershipData.planId,
      ...(!!membershipData.planPricePoint && {
        planPricePointId: membershipData.planPricePoint.planPricePointId
      })
    })
      .unwrap()
      .then(() => {
        dispatch(
          setUser({
            activePricePoint: membershipData.planPricePoint?.planPricePointId,
            activePlanId: selectedPlan?._id,
            isUnlimitedPlan: !!selectedPlan?.isUnlimitedPlan
          })
        );
        scheduleAppointment();
      });
  };

  const moveToStep: Props['moveToStep'] = (type, extraSearch = ''): void | Promise<void> => {
    const additionalSearch = extraSearch ? '&' + extraSearch : '';
    const currentStepIndex = steps().indexOf(currentStep);
    // if we need to store some data in redux, let's do it here
    const payload = buildPayload(type);
    !!payload && dispatch(setNewAppointmentExtended(payload));

    // when arg type is an object, then we'll have conditional next step, so below is the logic to define it
    if (typeof type === 'object') {
      const nextStep = getNextStep(type, src || srcFromQuery);
      if (nextStep === 'shop') {
        return navigate({ pathname: PathName.Shop });
      }
      const indexOfCurrentStep = steps().indexOf(type.step);
      const indexOfTheNextStep = !!nextStep ? steps().indexOf(nextStep) : 0;
      toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
      return nextStep ? navigate({ search: `s=${nextStep}${additionalSearch}` }) : exitFlow();
    } else if (type === 'prev') {
      toggleBackAnimation(true);
      return navigate(-1);
    } else if (type === 'next') {
      toggleBackAnimation(false);
      let nextStep = steps()[currentStepIndex + 1];
      return nextStep ? navigate({ search: `s=${nextStep}${additionalSearch}` }) : exitFlow();
    } else {
      if (!steps().includes(type)) {
        toggleBackAnimation(true);
        notifyError('Something went wrong, please try again');
        return navigate({ search: `s=${steps()[0]}` }, { replace: true });
      }
      toggleBackAnimation(false);
      navigate(
        { search: `s=${type}${additionalSearch}` },
        {
          replace: type === 'confirmation'
        }
      );
    }
  };

  const onInit = () => {
    if (srcFromQuery) {
      const correctSRCOptions: CareTypes[] = ['prescriptions', 'talk-to-a-doctor', 'shop-labs'];
      setNewAppointmentExtended({
        src: correctSRCOptions.includes(srcFromQuery) ? srcFromQuery : 'talk-to-a-doctor'
      });
    }
    if (!accessToken) {
      return setSteps([...CREATE_APPOINTMENT_STEPS]);
    }
    getMyAccount()
      .unwrap()
      .then(() => {
        if (
          (currentStep !== 'confirmation' && rest.status === 'created') ||
          currentStep === 'choose-type-of-care'
        ) {
          dispatch(clearNewAppointmentExtended());
        }
        if (predefinedCategory) {
          dispatch(setNewAppointmentExtended({ displayName: predefinedCategory }));
        }
        if (dateFromQuery) {
          dispatch(setNewAppointmentExtended({ initialDate: dateFromQuery }));
        }
        const filteredSteps: StepName[] = defineFlowSteps(CREATE_APPOINTMENT_STEPS, {
          // TODO - will define conditions for the lifemd plus
          isLifeMDPlusIncluded: isUnlimitedPlan,
          isInsurancePatient,
          isExistingPatient: !!accessToken
        });
        setSteps(filteredSteps);
        if (!currentStep || !filteredSteps.includes(currentStep)) {
          navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
          dispatch(clearNewAppointmentExtended());
        }
      });
  };

  useEffect(onInit, []);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
    if (rest.status === 'created' && currentStep !== 'confirmation') {
      dispatch(clearNewAppointmentExtended());
      navigate(PathName.Appointments, { replace: true });
    }
    if (!currentStep) {
      navigate({ search: 's=choose-type-of-care' }, { replace: true });
    }
  }, [currentStep]);

  return {
    selectedPlan,
    currentStep,
    isFetching: isFetching || isFetchingPlans || isFetchingApptTypes,
    loading: isLoadingUpdateUser || isLoadingAddAppointment || isFetching || isLoadingApptTypes,
    moveToStep,
    steps: steps(),
    exitFlow,
    isBackAnimation: isBackAnimation(),
    currentPlan,
    handleSelectTime,
    handleUpgradePlan,
    appointmentTypes: appointmentTypes?.data ?? []
  };
};
