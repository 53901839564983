import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { selectOrchestrate } from 'store';

import ExistingAccount from 'modals/ExistingAccount';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import EmailInput from 'shared/form/EmailInput';
import TextInput from 'shared/form/TextInput';
import CheckboxLabel from 'widgets/signUp/CheckboxLabel';

import { useAppSelector } from 'hooks';

type BasicFormInfo = {
  email: string;
  firstName: string;
  lastName: string;
};

const PersonalDetails: React.FC<{ onContinue: (data: BasicFormInfo) => void }> = ({
  onContinue
}) => {
  const [isOpenExistingAccount, toggleExistingAccount] = useToggle(false);
  const [isChecked, toggle] = useToggle(false);
  const [isCheckboxError, toggleCheckboxError] = useToggle(false);
  const { user } = useAppSelector(selectOrchestrate);

  const { control, handleSubmit, reset, getValues, setError } = useForm<BasicFormInfo>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const submit = (data: BasicFormInfo) => {
    if (data) {
      onContinue(data);
    }
  };

  const handleContinueWithLogin = () => {
    sessionStorage.setItem('suggest-wm-after-login', 'true');
  };

  useEffect(() => {
    reset(
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      },
      { keepDirty: true }
    );
    if (user.email && user.firstName && user.lastName) {
      toggle(true);
    }
  }, []);

  return (
    <FadeWrapper className="flex h-full flex-col gap-6" key={'form'}>
      <ExistingAccount
        email={getValues('email')}
        isOpen={isOpenExistingAccount}
        onClickContinue={handleContinueWithLogin}
        onClickReturn={() => {
          setError(
            'email',
            {
              message: 'Email is already in use'
            },
            { shouldFocus: true }
          );
          toggleExistingAccount();
        }}
        onClose={toggleExistingAccount}
      />
      <h3 className="main-header text-primary-700">Personal details</h3>
      <form className="flex h-full flex-col" onSubmit={handleSubmit(submit)}>
        <div className="grid grid-cols-2 gap-6">
          <TextInput
            control={control}
            dataTestId="first_name"
            invalidErrorMsg="First name is invalid"
            label="Full name"
            name="firstName"
            placeholder="First"
            requiredErrorMsg="First name is required"
            size="lg"
          />
          <TextInput
            className="pt-8"
            control={control}
            dataTestId="last_name"
            invalidErrorMsg="Last name is invalid"
            name="lastName"
            placeholder="Last"
            requiredErrorMsg="Last name is required"
            size="lg"
          />
          <div className="col-span-2">
            <EmailInput
              control={control}
              dataTestId="email_field"
              label="Email"
              name="email"
              placeholder="email@address.com"
              size="lg"
            />
          </div>
        </div>
        <div className="mt-6">
          <Common.Checkbox
            checked={isChecked}
            color="blue"
            name="isChecked"
            onChange={() => {
              toggleCheckboxError(false);
              toggle();
            }}
          >
            <CheckboxLabel isError={isCheckboxError} placement="wm-flow" />
          </Common.Checkbox>
        </div>
        <Common.Button className="max-md:mt-auto md:mx-auto md:mt-6" color="blue" fullWidthOnMobile>
          Continue
        </Common.Button>
      </form>
    </FadeWrapper>
  );
};

export default PersonalDetails;
