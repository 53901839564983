import { MY_ACCOUNT_TABS } from 'utils/constants';
import { PathName } from 'utils/enums';

export const getSelectedTab = () =>
  MY_ACCOUNT_TABS.find((el) => location.pathname.includes(el.value.path))?.label || '';

export const getVisibleTabs = (isFreemium: boolean, hasPassword: boolean) =>
  MY_ACCOUNT_TABS.filter(
    (el) =>
      !(el.value.path === PathName.BillingDetails && isFreemium) &&
      !(el.value.path === PathName.ChangePassword && !hasPassword)
  );
