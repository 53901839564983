// import { ErrorInfo } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import packageInfo from '../../package.json';

const initDatadog = () => {
  datadogRum.init({
    allowedTracingUrls: [import.meta.env.VITE_BASE_URL],
    applicationId: import.meta.env.VITE_DATA_DOG_APP_ID,
    clientToken: import.meta.env.VITE_DATA_DOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: import.meta.env.VITE_ENV,
    service: 'patient_fe',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    traceSampleRate: 20,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: packageInfo.version
  });

  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATA_DOG_CLIENT_TOKEN,
    env: import.meta.env.VITE_ENV,
    forwardConsoleLogs: ['error'],
    forwardErrorsToLogs: true,
    service: 'patient_fe',
    site: 'datadoghq.com',
    version: packageInfo.version
  });
};

// const logError = (error: Error, info: ErrorInfo) => {
//   datadogLogs.logger.error(error.message, info);
// };

export { initDatadog };
