import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';

const ExistingAccount: React.FC<
  BaseModalProps & { email: string; onClickContinue: () => void; onClickReturn: () => void }
> = ({ isOpen, email, onClickContinue, onClickReturn }) => {
  return (
    <Common.Modal isOpen={isOpen} padding={false} size="sm" persist>
      <div className="flex flex-col items-center gap-6 p-4">
        <div>
          <h2 className="mb-2 text-mXl font-bold md:text-xl" data-testid="header">
            We've found an existing account
          </h2>
          <span data-testid="sub_header">
            It looks like there's already a LifeMD account associated with this email address.
            Continue with this account?
          </span>
        </div>
        <span className="font-bold text-primary" data-testid="email">
          {email}
        </span>
        <div className="flex w-full flex-col gap-4 bg-gray-50/90 p-4 md:gap-3">
          <Common.Button
            className="md:!w-full"
            color="blue"
            dataTestId="yes_sign_in_and_continue_btn"
            fullWidthOnMobile
            onClick={onClickContinue}
          >
            Yes, sign in & continue
          </Common.Button>
          <Common.Button
            className="md:!w-full"
            color="white-alt"
            dataTestId="no_create_new_account_btn"
            fullWidthOnMobile
            onClick={onClickReturn}
          >
            No, create new account
          </Common.Button>
        </div>{' '}
      </div>
    </Common.Modal>
  );
};

export default ExistingAccount;
